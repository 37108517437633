import React from "react"
import { createPortal } from "react-dom"
import { useModal } from "../providers/Modal"

const Modal = (props) => {
  const { modalId, showModal } = useModal()

  if (modalId === props.id || props.forceOpen) {
    if (!props.scroll) {
      document.body.classList.add("noscroll")
    }
    let darkClass = ""
    if (props.darkBackdrop) {
      darkClass = "dark"
    }
    let sizeClass = ""
    if (props.medium) {
      sizeClass = "medium"
    }
    return createPortal(
      <>
        <div className={`modal ${darkClass}`} id={props.id}>
          {modalId && props.locked ? (
            <div className={`modal-backdrop locked`}></div>
          ) : (
            <div className={`modal-backdrop`} onClick={() => showModal("")}></div>
          )}

          <div className={`modal-dialog ${sizeClass}`}>
            {/* {props.closer && ( */}
            {/* <div className="modal-ds-closer" onClick={() => showModal("")}>
              <svg
                id="baseline-clear-24px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Path_926"
                  className="fill-color-dark"
                  data-name="Path 926"
                  d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                />
                <path id="Path_927" data-name="Path 927" d="M0,0H24V24H0Z" fill="none" />
              </svg>
            </div> */}
            {/* )} */}
            <div className="modal-content">{props.children}</div>
          </div>
        </div>
      </>,
      document.getElementById("modalRoot")
    )
  }
  return ""
}
function Head({ children }) {
  return (
    <div className="modal-head d-flex flex-column">
      <div className="dialog-title-wrap">
        <h1 className="dialog-title">{children}</h1>
      </div>
    </div>
  )
}
function Body({ children, className = "" }) {
  return <div className={`modal-body flex-1 ${className}`}>{children}</div>
}
function Foot({ children }) {
  return (
    <div className="modal-foot">
      <div className="w-100 d-flex justify-content-end">
        <div className="w-100">{children}</div>
      </div>
    </div>
  )
}

Modal.Head = Head
Modal.Body = Body
Modal.Foot = Foot
export default Modal
