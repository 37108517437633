import {useMutation} from "@apollo/client"
import React from "react"
import {Link} from "react-router-dom"
import {REMOVE_PRODUCT_FROM_REQUEST, UPDATE_REQUEST_PRODUCT, UPDATE_REQUEST_PRODUCT_COMMENT, UPDATE_REQUEST_PRODUCT_COPY} from "../../../graphql/mutation"
import {useModal} from "../../../providers/Modal"
import Modal from "../../../components/Modal"
import {useForm} from "react-hook-form"
import {toast} from "react-hot-toast"
import getGeneratedThumbPath from "../../../utils/getGeneratedThumbPath"
import {useInit} from "../../../providers/Init"
import Ratio23 from "../../../assets/images/ratio2-3.png"
import MaterialChooser from "../../../components/MaterialChooser"
import SpinLoader from "../../../components/loaders/SpinLoader"

export default function ProductRequestRow({rp, hideAction = false}) {
  // const [comment, setComment] = React.useState(rp.comment)
  // const [material, setMaterial] = React.useState(rp.material)
  const {register, handleSubmit, setValue, reset, setFocus} = useForm()
  const {showModal, modalId} = useModal()

  const [RemoveProduct, {loading: removeLoading, data}] = useMutation(REMOVE_PRODUCT_FROM_REQUEST, {
    variables: {
      requestProductId: rp.id,
    },
    refetchQueries: ["GetRequestDetail"],
    onCompleted: () => {
      toast.success("Removed")
      // setComment("")
    },
  })
  const [UpdateRequestProduct, {loading: updateLoading, data: updateData}] = useMutation(UPDATE_REQUEST_PRODUCT, {})
  const [CopyRequestProduct, {loading: copyLoading}] = useMutation(UPDATE_REQUEST_PRODUCT_COPY, {})
  const submitComment = async (data) => {
    const response = await UpdateRequestProduct({
      variables: {
        requestProductId: rp.id,
        comment: data.comment,
        materialId: rp.material?.id,
      },
      refetchQueries: ["GetRequestDetail"],
    })
    // setComment(data.comment || comment)
    showModal("")
    toast.success("Saved")
  }
  const changeMaterial = async (mat) => {
    // console.log("id: ", id);
    const response = await UpdateRequestProduct({
      variables: {
        requestProductId: rp.id,
        comment: rp.comment,
        materialId: mat.id,
      },
      refetchQueries: ["GetRequestDetail"],
    })
    // setMaterial(mat)
    toast.success("Saved")
  }
  const submitCopy = async () => {
    const response = await CopyRequestProduct({
      variables: {
        requestProductId: rp.id,
      },
      refetchQueries: ["GetRequestDetail"],
    })
    toast.success("Copied")
  }

  React.useEffect(() => {
    if (modalId) {
      setFocus("comment")
      reset({
        comment: rp.comment,
      })
    }
  }, [modalId])
  // if (data?.RemoveProductToRequest) return null

  if (!rp?.product) return ""

  let thumbUrl = ""
  if (rp.product?.thumbnail) {
    thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(rp.product?.thumbnail.mediaImageGenerateds, "PRODUCT_MEDIUM")
  }

  return (
    <>
      <div className="product-list-table-body-row">
        <SpinLoader absolute={true} small={true} loading={updateLoading || copyLoading || removeLoading} />
        <div className="product-list-table-name">
          <div className="d-flex align-items-center">
            <Link
              className="order-thumb small"
              to={`/product/${rp.product?.variantions[0].id}`}
              //  to={`/product/${rp.product.id}`}
              style={{backgroundImage: `url('${thumbUrl}')`}}
            >
              <img src={Ratio23} alt="" />
            </Link>
            <Link to={`/product/${rp.product?.variantions[0].id}`}>{rp.product.name}</Link>
          </div>
        </div>
        <div className="product-list-table-code">{rp.product.code}</div>
        <div className="product-list-table-material">
          <MaterialChooser setMaterial={changeMaterial} material={rp.material} id={rp.id} type="request" />
          {/* {rp.product.material} */}
        </div>
        <div className="product-list-table-comment">
          {!rp.comment ? (
            <u className="cursor-pointer" onClick={() => showModal(`writeCommentModal${rp.id}`)}>
              Comment
            </u>
          ) : (
            <>
              <div className="two-line-text">{rp.comment}</div>
              <u className="cursor-pointer" onClick={() => showModal(`writeCommentModal${rp.id}`)}>
                Comment
              </u>
            </>
          )}
        </div>
        {!hideAction && (
          <div className="product-list-table-action">
            <div className="d-flex align-items-center justify-content-between">
              <div className="mr-small">
                <u className="cursor-pointer" onClick={submitCopy}>
                  Duplicate
                </u>
              </div>
              <div className="product-list-table-closer" onClick={RemoveProduct}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20.448" height="20.448" viewBox="0 0 20.448 20.448">
                  <g id="Group_1" data-name="Group 1" transform="translate(-1205.646 -358.276)">
                    <path id="Path_3" data-name="Path 3" d="M0,0H27.917" transform="translate(1206 358.63) rotate(45)" fill="none" stroke="#000" strokeWidth="1" />
                    <path id="Path_4" data-name="Path 4" d="M0,0H27.917" transform="translate(1206 378.37) rotate(-45)" fill="none" stroke="#000" strokeWidth="1" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal id={`writeCommentModal${rp.id}`} darkBackdrop>
        <Modal.Body>
          <div className="fs-20 mb-small">Comment</div>

          <form onSubmit={handleSubmit(submitComment)}>
            <div className="form-group">
              <div className="  ">
                <textarea type={"text"} {...register("comment")} defaultValue={rp.comment} className="form-control" />
              </div>
            </div>

            <button type="submit" className="btn btn-dark">
              Save
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
