import React from "react"
import ReactDOM from "react-dom/client"
import "./global.css"
import { RouterProvider } from "react-router-dom"
import reportWebVitals from "./reportWebVitals"
import routes from "./routes"
import Global from "./modules/global"

const Providers = React.lazy(() => import(/* webpackChunkName: "providers" */ "./providers"))

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<>...</>}>
      <Providers>
        <RouterProvider router={routes} />
        <Global />
      </Providers>
    </React.Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
