import React from "react"
import LoginForm from "./LoginForm"
import Logo from "../../assets/images/logo.png"

export default function LoginScreen() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img src={Logo} alt="" style={{width: "180px", marginBottom: "40px"}} />
      <div style={{maxWidth: "540px"}}>
        {/* <h1 className="page-title text-center ">My account</h1> */}
        <p className=" text-center">For your personalized Trade account, please contact us at sales@munkhcashmere.mn and on B2B platform you have access to all our collections and products.</p>
        <LoginForm />
      </div>
    </div>
  )
}
