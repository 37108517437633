import PhotoItem from "../../../components/PhotoItem"

export default function RelatedProducts({products}) {
  let varians = [],
    updatedVarians = []
  products.forEach((rp) => {
    if (rp?.productRelated?.variantions?.length > 0) varians.push(...rp?.productRelated?.variantions)
  })
  if (!varians || varians?.length === 0) return ""

  // varians.forEach((v) => {
  //   let tmp = v
  //   console.log("v:", v)
  //   if (v.photos?.length > 0) {
  //     let photos = []
  //     v.photos.forEach((p) => {
  //       console.log("p:", p)
  //       photos.push({
  //         id2: p.id,
  //         isThumbnail2: p.isThumbnail,
  //         mediaId2: p.photoId,
  //         mediaImageGenerateds2: p.photo?.mediaImageGenerateds,
  //       })
  //     })
  //     tmp.photos = photos
  //   } else {
  //     tmp.photos = []
  //   }

  //   updatedVarians.push(tmp)
  // })
  // console.log("updatedVarians:", updatedVarians)
  return (
    <>
      <div className="mb-small mt-medium">
        <h1>Related products</h1>
      </div>

      <div className="photos-wrap ">
        {varians.map((v, key) => {
          return <PhotoItem photo={v} key={key} index={key} rawPhoto={true} />
        })}
      </div>
    </>
  )
}
