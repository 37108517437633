import React, {Fragment} from "react"

const PhotoSkelotonLoader = ({loading = true, repeat = 1}) => {
  if (!loading) return ""

  const items = []
  for (let i = 0; i < repeat; i++) {
    items.push(
      <div className={`list-item`} key={i}>
        <div className="product">
          <div className="d-flex w-100 ">
            <svg
              className="sk-loader post-sk-loader"
              id="Group_1450"
              data-name="Group 1450"
              xmlns="http://www.w3.org/2000/svg"
              width="350"
              height="600"
              viewBox="0 0 350 600"
            >
              <defs>
                <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="linearGradientPCP">
                  <stop offset="0" />
                  <stop offset="0.3" />
                  <stop offset="0.7" />
                  <stop offset="1" />
                </linearGradient>
              </defs>
              <g id="post.cp.loader">
                <svg xmlns="http://www.w3.org/2000/svg" width="350" height="600" viewBox="0 0 350 600">
                  <rect id="Rectangle_656" data-name="Rectangle 656" width="350" height="525" fill="#fff" />
                  <g id="Rectangle_657" data-name="Rectangle 657" transform="translate(15 525)" fill="#fff" opacity="0">
                    <rect width="320" height="75" stroke="none" />
                    <rect x="0.5" y="0.5" width="319" height="74" fill="none" />
                  </g>
                  <rect id="Rectangle_658" data-name="Rectangle 658" width="160" height="18" transform="translate(15 548)" fill="#fff" />
                  <rect id="Rectangle_659" data-name="Rectangle 659" width="99" height="11" transform="translate(15 573)" fill="#fff" />
                </svg>
              </g>

              <rect
                className="sk-animate-loader overlay-post"
                id="Rectangle_1963"
                data-name="Rectangle 1963"
                width="850"
                height="600"
                fill="url(#linearGradientPCP)"
              />
            </svg>
          </div>
        </div>
      </div>
    )
  }
  return <Fragment>{items}</Fragment>
}
export default PhotoSkelotonLoader
