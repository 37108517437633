import React, { useRef, useEffect, useState, useContext, createContext } from "react"

const DropdownContext = createContext()

const Dropdown = (props) => {
  const [open, setOpen] = useState(false)

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setOpen, props.hoverAble)

  return (
    <DropdownContext.Provider
      value={{
        open,
        setOpen,
        hoverAble: props.hoverAble,
      }}
    >
      <div
        className={`dropdown ${props.className} ${props.right ? "right" : ""}`}
        ref={wrapperRef}
        onMouseLeave={() => (props.hoverAble ? setOpen(false) : {})}
      >
        {props.children}
      </div>
    </DropdownContext.Provider>
  )
}
const Toggle = ({ children }) => {
  const { open, setOpen, hoverAble } = useContext(DropdownContext)
  const [delayHandler, setDelayHandler] = React.useState(null)
  const handleMouseEnter = (event) => {
    if (hoverAble)
      setDelayHandler(
        setTimeout(() => {
          setOpen(true)
        }, 700)
      )
  }

  const handleMouseLeave = () => {
    if (hoverAble) clearTimeout(delayHandler)
  }

  return (
    <div
      className={`w-100 ${open ? "dropdown-opened" : ""}`}
      onClick={() => setOpen(!open)}
      // onMouseEnter={() => (hoverAble ? setOpen(true) : {})}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // onMouseLeave={() => (hoverAble ? setOpen(false) : {})}
    >
      {children}
    </div>
  )
}
const Menu = ({ children, className, style }) => {
  const { open } = useContext(DropdownContext)
  if (!open) return ""
  return (
    <div className={`dropdown-content ${className}`} style={style}>
      {children}
    </div>
  )
}
const ListItem = (props) => {
  const { open, setOpen } = useContext(DropdownContext)

  const clickEvent = (e) => {
    setOpen(false)
    if (props.onClick) {
      props.onClick(e)
    }
  }
  if (!open) return ""

  return (
    <div className="dropdown-listitem" onClick={clickEvent}>
      {props.children}
    </div>
  )
}
const ColorItem = (props) => {
  const { open, setOpen } = useContext(DropdownContext)

  const clickEvent = (e) => {
    setOpen(false)
    if (props.onClick) {
      props.onClick(e)
    }
  }
  if (!open) return ""

  return (
    <div className={props.className} style={props.style} onClick={clickEvent}>
      {props.children}
    </div>
  )
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setOpen, hoverAble) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false)
      // ref.current.children[0].classList.remove("open")
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}
const Divider = () => {
  return (
    <div className="dropdown-divider">
      <hr />
    </div>
  )
}
const Label = ({ children }) => {
  return <div className="dropdown-label">{children}</div>
}
Dropdown.Toggle = Toggle
Dropdown.Menu = Menu
Dropdown.ListItem = ListItem
Dropdown.ColorItem = ColorItem
Dropdown.Divider = Divider
Dropdown.Label = Label
export default Dropdown
