import React from "react"
import {useMutation, useQuery} from "@apollo/client"
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom"
import {GET_PRODUCT_DETAIL, GET_VARIANTION} from "../../graphql/query"
import Ratio23 from "../../assets/images/ratio2-3.png"
import AddToRequestButton from "./components/AddToRequestButton"
import AddToOrderButton from "./components/AddToOrderButton"
import SpinLoader from "../../components/loaders/SpinLoader"
import StickyWrapper from "../../components/StickyWrapper"
import getGeneratedThumbPath from "../../utils/getGeneratedThumbPath"
import ImageZoom from "./components/ImageZoom"
import $ from "jquery"
import PhotoItem from "../../components/PhotoItem"
import RelatedProducts from "./components/RelatedProducts"
import {useAuth} from "../../providers/Auth"
import {useInit} from "../../providers/Init"
import Dropdown from "../../components/Dropdown"
import {useModal} from "../../providers/Modal"
import {CREATE_FOLDER_PHOTO, DELETE_FOLDER_PHOTO} from "../../graphql/mutation"
import {toast} from "react-hot-toast"
import CreateFolderButton from "../folder/components/CreateFolderButton"
import CreateFolderModal from "../folder/components/CreateFolderModal"
import ReactImageMagnify from "react-image-magnify"

export default function ProductScreen() {
  const navigate = useNavigate()
  const location = useLocation()

  let {id, categoryId} = useParams()
  let [searchParams] = useSearchParams()
  const {me} = useAuth()
  const {latestSavedFolder, setLatestSavedFolder} = useInit()
  const {modalId, showModal} = useModal()

  const [SavePhoto] = useMutation(CREATE_FOLDER_PHOTO, {
    refetchQueries: ["Me"],
  })
  const [DeletePhoto] = useMutation(DELETE_FOLDER_PHOTO, {
    refetchQueries: ["GetFolderDetail", "GetFolderPhotoList", "Me"],
  })
  const [savedOn, setSaved] = React.useState(false)

  const onSubmit = async (f, photoId) => {
    const response = await SavePhoto({
      variables: {
        folderId: f.id,
        photoId: photoId,
      },
    })
    setLatestSavedFolder(f)
    setSaved(true)
    toast.success("Saved")
  }

  // variantion

  const {data, loading} = useQuery(GET_VARIANTION, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "cache-first",
  })

  const colPhoto = React.useRef(null)
  const colDesc = React.useRef(null)
  const [off, setOff] = React.useState(false)

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (colPhoto.current?.clientHeight < colDesc.current?.clientHeight) {
      setOff(true)
    }
  }, [colPhoto, colDesc])

  if (loading)
    return (
      <div className="pos-rel h-100">
        <SpinLoader loading={loading} />
      </div>
    )

  if (!data) return "not found"

  const variantion = data?.GetProductVariantion

  const materialsString = variantion.tags
    .filter((ts) => ts.tag.group.slug == "material")
    .map((ts) => ts.tag.name)
    .join(", ")
  const sizesString = variantion.tags
    .filter((ts) => ts.tag.group.slug == "size")
    .map((ts) => ts.tag.name)
    .join(", ")
  const colorsString = variantion.tags
    .filter((ts) => ts.tag.group.slug == "color")
    .map((ts) => ts.tag.name)
    .join(", ")
  const colorsCodeString = variantion.tags
    .filter((ts) => ts.tag.group.slug == "color")
    .map((ts) => ts.tag.code)
    .join(", ")
  const modelsString = variantion.tags
    .filter((ts) => ts.tag.group.slug == "model")
    .map((ts) => ts.tag.name)
    .join(", ")

  let npPrefix = categoryId ? "/category/" + categoryId : "/product"

  let npBackparams = searchParams.get("bp") ? "?bp=" + searchParams.get("bp") : ""

  let currency
  if (variantion.product_variantion_price?.length > 0) {
    if (variantion.product_variantion_price[0].currencyId === "EUR") {
      currency = "€"
    } else if (variantion.product_variantion_price[0].currencyId === "USD") {
      currency = "$"
    } else if (variantion.product_variantion_price[0].currencyId === "RUB") {
      currency = "₽"
    }
  }

  return (
    <>
      {(searchParams.get("bp") || variantion.next || variantion.prev) && (
        <div className="mb-small d-flex justify-content-between">
          <div>
            <Link to={"#_"} onClick={() => navigate(-1)}>
              ← Back to list
            </Link>
            {/* {location?.state?.prevPath ? (
              <Link to={location.state.prevPath}>← Back to list1</Link>
            ) : (
              <Link to={"#_"} onClick={() => navigate(-1)}>
                ← Back to list
              </Link>
            )} */}
          </div>
          <div className="">
            {(variantion.next || variantion.prev) && (
              <div className="d-flex">
                {variantion.next && (
                  <Link to={`${npPrefix}/${variantion.next.id}${npBackparams}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" style={{transform: "rotate(180deg)", marginRight: "5px"}}>
                      <path id="Polygon_1" data-name="Polygon 1" d="M4.5,0,9,5H0Z" transform="translate(5) rotate(90)"></path>
                    </svg>
                    Previous
                  </Link>
                )}
                <div className="" style={{width: "20px"}}></div>
                {variantion.prev && (
                  <Link to={`${npPrefix}/${variantion.prev.id}${npBackparams}`}>
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" style={{marginLeft: "5px"}}>
                      <path id="Polygon_1" data-name="Polygon 1" d="M4.5,0,9,5H0Z" transform="translate(5) rotate(90)"></path>
                    </svg>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="row w-100" id="product-detail-wrap">
        <div className="col">
          <div className="gallery" ref={colPhoto}>
            {variantion.photos.map((photo, key) => {
              const thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(photo.mediaImageGenerateds, "PRODUCT_LARGE")

              return (
                <div key={key} className="product product-detail-image">
                  <div
                    className="thumb "
                    // style={{ backgroundImage: `url('${thumbUrl}')` }}
                  >
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          isFluidWidth: true,
                          src: thumbUrl,
                        },
                        largeImage: {
                          src: thumbUrl,
                          width: 1200,
                          height: 1800,
                        },
                        enlargedImagePosition: "over",
                      }}
                    />
                    {/* <img src={Ratio23} alt="" /> */}
                    <div className="thumb-action">
                      <a href={thumbUrl} download target={"_blank"} className="photo-download-btn d-flex align-items-center justify-content-center">
                        Download
                      </a>

                      <>
                        {me.user?.folder?.length > 0 ? (
                          <div className="d-flex align-items-center">
                            <Dropdown className={"folder-dropdown"}>
                              <Dropdown.Toggle>
                                <Link to="#_" className="folder-dropdown-selected">
                                  <div className="">{latestSavedFolder?.name}</div>
                                  {latestSavedFolder?.name && (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="5" viewBox="0 0 12 5">
                                      <path id="Polygon_2" data-name="Polygon 2" d="M6,0l6,5H0Z" transform="translate(12 5) rotate(180)" fill="#fff" />
                                    </svg>
                                  )}
                                </Link>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="">
                                {me.user?.folder?.length > 0 &&
                                  me.user.folder.map((f, key) => {
                                    return (
                                      <Dropdown.ListItem key={key}>
                                        <Link to="#_" className="folder-dropdown-item">
                                          <div className="">{f.name}</div>
                                          <div className="btn btn-dark btn-small" onClick={() => onSubmit(f, photo.id)}>
                                            Save to folder
                                          </div>
                                        </Link>
                                      </Dropdown.ListItem>
                                    )
                                  })}

                                <Dropdown.Divider></Dropdown.Divider>

                                <CreateFolderButton photoId={photo.id} />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : (
                          <a href="#_" className="photo-download-btn d-flex align-items-center justify-content-center" onClick={() => showModal(`createFolderModal${photo.id}`)}>
                            Save to folder
                          </a>
                        )}
                      </>
                    </div>
                  </div>
                  <CreateFolderModal photoId={photo.id} />
                </div>
              )

              {
                /* return (
                <div
                  key={key}
                  id={`PT_${key}`}
                  className="image gallery-image bg-cover"
                  style={{backgroundImage: `url('${bgUrl}')`}}
                  data-zoom-image={bgUrl}
                  // style={{backgroundImage: `url('${process.env.REACT_APP_API_URL}${photo.mediaImageGenerateds[0].path}')`}}
                >
                  <img className="w-100" src={Ratio23} alt="" />
                </div>
              ) */
              }
            })}
          </div>
        </div>
        <div className="col w-100">
          <StickyWrapper off={off}>
            <div className="product-detail" ref={colDesc}>
              <div id="product-14553">
                <h3 className="title ">{variantion.product.name}</h3>
                <div className="price-area d-flex justify-content-start align-items-center mb-5">
                  {variantion.product_variantion_price?.length > 0 && (
                    <>
                      {variantion.product_variantion_price[0].sale_price > 0 ? (
                        <>
                          <span className="price has-sale">
                            {currency} {variantion.product_variantion_price[0].main_price}
                          </span>
                          <span className=" " style={{marginLeft: "15px"}}>
                            {currency} {variantion.product_variantion_price[0].sale_price}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="price">
                            {" "}
                            {currency} {variantion.product_variantion_price[0].main_price}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* <h5 className="title">Description</h5> */}
                {variantion.product.description && <p>{variantion.product.description}</p>}

                <table className="detail-table">
                  <tbody>
                    {variantion.product.code && (
                      <tr>
                        <td>Code</td>
                        <td>{variantion.product.code}</td>
                      </tr>
                    )}

                    {variantion.product.gauge && (
                      <tr>
                        <td>Gauge</td>
                        <td>{variantion.product.gauge}</td>
                      </tr>
                    )}

                    {variantion.product.ply && (
                      <tr>
                        <td>Ply</td>
                        <td>{variantion.product.ply}</td>
                      </tr>
                    )}

                    {variantion.product.stitch && (
                      <tr>
                        <td>Stitch</td>
                        <td>{variantion.product.stitch}</td>
                      </tr>
                    )}

                    {variantion.product.size && (
                      <tr>
                        <td>Size</td>
                        <td>{variantion.product.size}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <h5 className="title">Image details</h5>

                <table className="detail-table">
                  <tbody>
                    {materialsString && (
                      <tr>
                        <td>Material</td>
                        <td>{materialsString}</td>
                      </tr>
                    )}
                    {sizesString && (
                      <tr>
                        <td>Size</td>
                        <td>{sizesString}</td>
                      </tr>
                    )}
                    {colorsString && (
                      <tr>
                        <td>Color</td>
                        <td>{colorsString}</td>
                      </tr>
                    )}
                    {colorsCodeString && (
                      <tr>
                        <td>Color code</td>
                        <td>{colorsCodeString}</td>
                      </tr>
                    )}
                    {modelsString && (
                      <tr>
                        <td>Model</td>
                        <td>{modelsString}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="mt-medium">
                  <AddToOrderButton productId={variantion.product.id} />
                  <AddToRequestButton productId={variantion.product.id} />
                </div>
              </div>
            </div>
          </StickyWrapper>
        </div>
      </div>

      {data?.GetProductVariantion?.product?.product?.length > 0 && <RelatedProducts products={data.GetProductVariantion.product.product} />}

      {variantion?.product?.variantions?.length > 1 && (
        <>
          <div className="mb-small mt-medium">
            <h1>Linked variations</h1>
          </div>

          <div className="photos-wrap ">
            {variantion.product.variantions
              ?.filter((f) => f.id !== variantion.id)
              .map((photo, key) => {
                return <PhotoItem photo={photo} key={key} index={key} />
              })}
          </div>
        </>
      )}
    </>
  )
}
