import { Toaster } from "react-hot-toast"
export default function Global() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        // toastOptions={{
        //   className: "",
        //   style: {
        //     borderRadius: "0px",
        //     // boxShadow: "none",
        //   },
        // }}
      />
    </>
  )
}
