import { useMutation } from "@apollo/client"
import React from "react"
import { toast } from "react-hot-toast"
import SpinLoader from "../../../components/loaders/SpinLoader"
import { ADD_PRODUCT_TO_ORDER } from "../../../graphql/mutation"

export default function AddToOrderButton({ productId }) {
  const [AddToOrder, { loading, data }] = useMutation(ADD_PRODUCT_TO_ORDER, {
    variables: {
      productId: productId,
    },
    refetchQueries: ["GetBadges"],
    onCompleted: () => {
      toast.success("Added to your order")
    },
  })

  return (
    <button className="btn btn-dark mr-mini " onClick={AddToOrder} disabled={loading}>
      <div className="d-flex align-items-center justify-content-center">
        <SpinLoader loading={loading} small light /> Add to order
      </div>
    </button>
  )
}
