import {useMutation} from "@apollo/client"
import {UPDATE_ORDER_PRODUCT_ATTRIBUTE} from "../graphql/mutation"
import {useInit} from "../providers/Init"
import {useModal} from "../providers/Modal"
import Dropdown from "./Dropdown"
import Modal from "./Modal"
import {useEffect, useState} from "react"
import getGeneratedThumbPath from "../utils/getGeneratedThumbPath"

export default function ColorChooser({data, color, setColor, id}) {
  // const {colors} = useInit()
  const [search, setSearch] = useState()
  const colors = data
  const {modalId, showModal} = useModal()
  const [UpdateOrderProductAttribute] = useMutation(UPDATE_ORDER_PRODUCT_ATTRIBUTE)

  const choose = async (choosedColor) => {
    setColor(choosedColor)
    showModal("")
    setSearch("")
  }

  useEffect(() => {
    if (!modalId && search) {
      setSearch("")
    }

    return () => {}
  }, [modalId, search])

  if (!colors || colors.length === 0) return ""

  let thumb = ""

  if (color) {
    if (color.thumbnail?.mediaImageGenerateds?.length > 0) {
      thumb = process.env.REACT_APP_API_URL + getGeneratedThumbPath(color.thumbnail.mediaImageGenerateds, "DEFAULT_THUMB")
    } else {
      thumb = "/colors/" + color.code + "_512.jpg"
    }
  }

  return (
    <>
      <button className="btn-choose-attr" onClick={() => showModal(`colorModal${id}`)}>
        {color ? (
          <div className="attr-list-item choosed" onClick={() => showModal(`colorModal${id}`)}>
            <div className="attr-thumb" style={{backgroundImage: `url(${thumb})`}}></div>
            <div className="attr-name">
              <div>{color.name}</div>
              <div className="text-color-secondary fs-12">Color code: {color.code}</div>
            </div>
          </div>
        ) : (
          <u>Select color</u>
        )}
      </button>

      <Modal medium id={`colorModal${id}`}>
        <Modal.Body>
          <div className="mb-small d-flex align-items-center justify-content-between">
            <div className="fs-20 ">Choose color</div>
            <div className="">
              <input
                type="text"
                defaultValue={search}
                onChange={(e) => setSearch(e.target.value)}
                className="form-control"
                placeholder="Search color"
              />
            </div>
          </div>
          <div className="attr-list-group">
            {search ? (
              <>
                {colors
                  .filter((f) => f.child.name.toLowerCase().includes(search.toLowerCase()))
                  .map((m, k) => {
                    let mt = ""
                    if (m.child.thumbnail?.mediaImageGenerateds?.length > 0) {
                      mt = process.env.REACT_APP_API_URL + getGeneratedThumbPath(m.child.thumbnail.mediaImageGenerateds, "DEFAULT_THUMB")
                    } else {
                      mt = "/colors/" + m.child.code + "_512.jpg"
                    }

                    return (
                      <div className="attr-list-item" key={k} onClick={() => choose(m.child)}>
                        <div className="attr-thumb" style={{backgroundImage: `url(${mt})`}}></div>
                        <div className="attr-name">
                          {m.child.name} ({m.child.code})
                        </div>
                      </div>
                    )
                  })}
              </>
            ) : (
              <>
                {colors.map((m, k) => {
                  let mt = ""
                  if (m.child.thumbnail?.mediaImageGenerateds?.length > 0) {
                    mt = process.env.REACT_APP_API_URL + getGeneratedThumbPath(m.child.thumbnail.mediaImageGenerateds, "DEFAULT_THUMB")
                  } else {
                    mt = "/colors/" + m.child.code + "_512.jpg"
                  }

                  return (
                    <div className="attr-list-item" key={k} onClick={() => choose(m.child)}>
                      <div className="attr-thumb" style={{backgroundImage: `url(${mt})`}}></div>
                      <div className="attr-name">
                        {m.child.name} ({m.child.code})
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
