import {useMutation} from "@apollo/client"
import {useForm} from "react-hook-form"
import {toast} from "react-hot-toast"
import {UPDATE_USER_INFO} from "../../graphql/mutation"
import {useAuth} from "../../providers/Auth"

export default function SettingsScreen() {
  const {register, handleSubmit, setValue} = useForm()
  const [UpdateInfo, {loading, data}] = useMutation(UPDATE_USER_INFO)
  const {me, setMe} = useAuth()
  // console.log("me: ", me)
  const onSubmit = async (data) => {
    const response = await UpdateInfo({
      variables: {
        firstname: data.firstname,
        lastname: data.lastname,
        businessName: data.businessName,
        phone: data.phone,
        clientDescription: data.clientDescription,
        businessDescription: data.businessDescription,
        numberOfStores: data.numberOfStores,
        specialInstructions: data.specialInstructions,
        shippingAddress: data.shippingAddress,
        billingAddress: data.billingAddress,
        website: data.website,
      },
    })
    if (response?.data?.UpdateInfo) {
      // console.log("|updateing .. ", { ...me, user: response.data.UpdateInfo })
      setMe({...me, user: response.data.UpdateInfo})
      toast.success("Saved")
    }
  }

  return (
    <div className="row">
      <div className="col-6">
        <div className="mb-medium">
          <h1>Settings</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="firstname">Email</label>
              <input type={"email"} disabled className="form-control disabled" defaultValue={me.user.email} />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="firstname">Firstname *</label>
              <input type={"text"} {...register("firstname", {required: true})} defaultValue={me.user.profile?.firstname} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="lastname">Lastname *</label>
              <input type={"text"} {...register("lastname", {required: true})} defaultValue={me.user.profile?.lastname} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="businessName">Business Name *</label>
              <input type={"text"} {...register("businessName", {required: true})} defaultValue={me.user.profile?.businessName} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="phone">Phone *</label>
              <input type={"text"} {...register("phone", {required: true})} defaultValue={me.user.profile?.phone} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="clientDescription">Clientele description *</label>
              <input type={"text"} {...register("clientDescription", {required: true})} defaultValue={me.user.profile?.clientDescription} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="businessDescription">Business description *</label>
              <input type={"text"} {...register("businessDescription", {required: true})} defaultValue={me.user.profile?.businessDescription} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="numberOfStores">Number of stores</label>
              <input type={"text"} {...register("numberOfStores")} defaultValue={me.user.profile?.numberOfStores} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="specialInstructions">Special instructions</label>
              <input type={"text"} {...register("specialInstructions")} defaultValue={me.user.profile?.specialInstructions} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="shippingAddress">Shipping address</label>
              <input type={"text"} {...register("shippingAddress")} defaultValue={me.user.profile?.shippingAddress} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="billingAddress">Billing address</label>
              <input type={"text"} {...register("billingAddress")} defaultValue={me.user.profile?.billingAddress} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="website">Website</label>
              <input type={"text"} {...register("website")} defaultValue={me.user.profile?.website} className="form-control" />
            </div>
          </div>

          <button type="submit" className="btn btn-dark">
            Save changes
          </button>
        </form>
      </div>
    </div>
  )
}
