import React, {useState} from "react"
import {useQuery} from "@apollo/client"
import {Link, useParams} from "react-router-dom"
import {GET_ORDER_DETAIL} from "../../graphql/query"
import ProductOrderRow from "./components/ProductOrderRow"
import SpinLoader from "../../components/loaders/SpinLoader"
import moment from "moment"
import SendOrderButton from "./components/SendOrderButton"
import DownloadOrderExcel from "./components/DownloadOrderExcel"
import DeleteOrderButton from "./components/DeleteOrderButton"
moment().format()

export default function OrderDetailScreen() {
  let {id} = useParams()

  const [products, setProducts] = useState()
  const {data, loading, refetch} = useQuery(GET_ORDER_DETAIL, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.GetOrderDetail?.products) {
        setProducts(data.GetOrderDetail.products)
      }
    },
  })


  React.useEffect(() => {}, [products?.length])


  if (loading)
    return (
      <div className="pos-rel h-100 list-loader-wrap">
        <SpinLoader loading={loading} />
      </div>
    )

  if (!data) return "not found"

  const order = data?.GetOrderDetail

  let date = moment(parseInt(order.createdAt)).format("LLLL")
  let updatedAt = order.updatedAt ? moment(parseInt(order.updatedAt)).format("LLLL") : ""

  // console.log("order", order)

  return (
    <>
      <div className="mb-medium d-flex align-items-center justify-content-between">
        <div>
          <h1 className="mb-0">
            {order.status === "active" && "New order detail"}
            {order.status === "pending" && "Pending order detail"}
            {order.status === "completed" && "Previous order detail"}
          </h1>
          {updatedAt && (
            <span className="text-color-secondary mr-small fs-12">
              <i>Last updated at {updatedAt}</i>
            </span>
          )}
        </div>
        <div className="">
          {order.status === "completed" && <DeleteOrderButton id={order.id} />}
          {order.status !== "completed" && <SendOrderButton id={order.id} />}
        </div>
      </div>
      <div className="bg-white">
        <div className="order-info mb-medium d-flex align-items-center justify-content-between">
          <div className="">{date}</div>

          <div className="d-flex align-items-center">
            <div className="text-color-secondary mr-small">{products?.length || 0} products</div>
            <DownloadOrderExcel id={id} />
          </div>
        </div>

        {products?.length > 0 ? (
          <div className="product-list-table prev-order-wrap">
            <div className="product-list-table-head">
              <div className="product-list-table-name">Product</div>
              <div className="product-list-table-code">Code</div>
              <div className="product-list-table-material">Material</div>
              <div className="product-list-table-color">Color</div>
              <div className="product-list-table-quantity">Quantity</div>
              <div className="product-list-table-comment">Comment</div>
              {order.status !== "completed" && <div className="product-list-table-action">Action</div>}
            </div>
            <div className="product-list-table-body">
              {products.map((rp, key) => {
                return <ProductOrderRow refetch={refetch} key={key} rp={rp} hideAction={order.status !== "completed" ? false : true} />
              })}
            </div>
          </div>
        ) : (
          <div className="text-center">No products</div>
        )}
      </div>
    </>
  )
}
