import React from "react"
import { Link } from "react-router-dom"
import Dropdown from "../../../components/Dropdown"
import { useModal } from "../../../providers/Modal"

export default function CreateFolderButton({ component, photoId }) {
  const { modalId, showModal } = useModal()

  const DropdownLinkItem = () => {
    return (
      <Dropdown.ListItem>
        <Link to="#_" onClick={() => showModal(`createFolderModal${photoId}`)} className="text-center">
          Create folder
        </Link>
      </Dropdown.ListItem>
    )
  }

  React.useEffect(() => {}, [])
  return (
    <>
      <DropdownLinkItem />
    </>
  )
}
