import React from "react"
import {useMutation, useQuery} from "@apollo/client"
import {Link} from "react-router-dom"
import {GET_REQUEST_LIST} from "../../graphql/query"
import useInfiniteScroll from "../../utils/InfiniteScroll"
import Ratio23 from "../../assets/images/ratio2-3.png"
import SendRequestButton from "./components/SendRequestButton"
import SpinLoader from "../../components/loaders/SpinLoader"
import getGeneratedThumbPath from "../../utils/getGeneratedThumbPath"
import {CLEAR_REQUEST_PRODUCTS} from "../../graphql/mutation"
import DownloadRequestExcel from "./components/DownloadRequestExcel"

export default function RequestScreen() {
  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const [ClearProducts] = useMutation(CLEAR_REQUEST_PRODUCTS, {
    refetchQueries: ["GetRequestList", "GetBadges"],
  })
  const {loading, fetchMore} = useQuery(GET_REQUEST_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setList(data.GetRequestList.edges.map((edge) => edge.node))
      setPageInfo(data.GetRequestList.pageInfo)
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      const moreresult = await fetchMore({
        variables: {
          skip: pageInfo.endCursor,
        },
      })
      setList(list.concat(moreresult.data.GetRequestList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.GetRequestList.pageInfo)
    }
  }

  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  if (loading)
    return (
      <div className="pos-rel h-100 list-loader-wrap">
        <SpinLoader loading={loading} />
      </div>
    )

  // console.log(list)
  return (
    <div>
      <div className="mb-medium">
        <h1>Price quotation requests</h1>
      </div>

      {list
        .filter((r) => r.status === "active")
        .map((r, key) => {
          return (
            <section className="mb-large" key={key}>
              <div className="mb-small ">New price quotation request</div>
              <div className="current-inorders-wrap">
                <div className="current-inorders-top">
                  <div className="inorders-item-wrap">
                    {r.products.slice(0, 8).map((rp, pkey) => {
                      if (!rp.product) return null
                      let thumbUrl = ""
                      if (rp.product?.thumbnail) {
                        thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(rp.product?.thumbnail.mediaImageGenerateds, "PRODUCT_MEDIUM")
                      }

                      return (
                        <Link
                          className="order-thumb small"
                          href={`/product/${rp.product.id}`}
                          key={pkey}
                          style={{backgroundImage: `url('${thumbUrl}')`}}
                        >
                          <img src={Ratio23} alt="" />
                        </Link>
                      )
                    })}
                  </div>
                </div>
                <div className="current-inorders-bottom d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span>{r.products?.length || 0} product</span>
                    <Link
                      href={`#_`}
                      onClick={() =>
                        ClearProducts({
                          variables: {
                            requestId: r.id,
                          },
                        })
                      }
                      className="ml-small text-color-secondary"
                    >
                      Clear all items
                    </Link>
                  </div>
                  <div className="d-flex align-items-center">
                    <DownloadRequestExcel id={r.id} />
                    <Link to={`/price-requests/${r.id}`} className="btn btn-dark">
                      {/* Show details */}
                      Show details & Continue
                    </Link>
                    {/* <SendRequestButton id={r.id} /> */}
                  </div>
                </div>
              </div>
            </section>
          )
        })}

      {list.filter((r) => r.status === "pending").length > 0 && (
        <section className="mb-large prev-order-wrap">
          <div className="mb-small">
            <div className="">Pending price quotation request</div>
          </div>
          <div className="orders-list-wrap">
            {list
              .filter((r) => r.status === "pending")
              .map((r, key) => {
                return (
                  <div className="orders-list" key={key}>
                    <div className="mb-small text-color-secondary">
                      {new Date(parseInt(r.createdAt)).toDateString()} - {r.products.length} products
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="orders-item-list">
                          {r.products.slice(0, 8).map((rp, pkey) => {
                            if (!rp.product) return null
                            let thumbUrl = ""
                            if (rp.product?.thumbnail) {
                              thumbUrl =
                                process.env.REACT_APP_API_URL + getGeneratedThumbPath(rp.product?.thumbnail.mediaImageGenerateds, "PRODUCT_MEDIUM")
                            }
                            return (
                              <Link
                                className="order-thumb small"
                                href={`/product/${rp.product.id}`}
                                key={pkey}
                                style={{
                                  backgroundImage: `url('${thumbUrl}')`,
                                }}
                              >
                                <img src={Ratio23} alt="" />
                              </Link>
                            )
                          })}
                        </div>
                        <div className="ml-small text-color-secondary">{r.products.length > 8 && `+${r.products.length - 8} more`}</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <DownloadRequestExcel id={r.id} />
                        <Link to={`/price-requests/${r.id}`} className="">
                          Show details
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </section>
      )}

      {list.filter((r) => r.status === "completed").length > 0 && (
        <section className="mb-large prev-order-wrap">
          <div className="mb-small">
            <div className="">Previous price quotation request</div>
          </div>
          <div className="orders-list-wrap">
            {list
              .filter((r) => r.status === "completed")
              .map((r, key) => {
                return (
                  <div className="orders-list" key={key}>
                    <div className="mb-small text-color-secondary">
                      {new Date(parseInt(r.createdAt)).toDateString()} - {r.products.length} products
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="orders-item-list">
                          {r.products.slice(0, 8).map((rp, pkey) => {
                            if (!rp.product) return null
                            let thumbUrl = ""
                            if (rp.product?.thumbnail) {
                              thumbUrl =
                                process.env.REACT_APP_API_URL + getGeneratedThumbPath(rp.product?.thumbnail.mediaImageGenerateds, "PRODUCT_MEDIUM")
                            }
                            return (
                              <Link
                                className="order-thumb small"
                                href={`/product/${rp.product.id}`}
                                key={pkey}
                                style={{
                                  backgroundImage: `url('${thumbUrl}')`,
                                }}
                              >
                                <img src={Ratio23} alt="" />
                              </Link>
                            )
                          })}
                        </div>
                        <div className="ml-small text-color-secondary">{r.products.length > 8 && `+${r.products.length - 8} more`}</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <DownloadRequestExcel id={r.id} />
                        <Link to={`/price-requests/${r.id}`} className="">
                          Show details
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </section>
      )}

      {pageInfo?.hasNextPage && <div className="lastDiv" ref={lastElementRef}></div>}
    </div>
  )
}
