import { useMutation } from "@apollo/client"
import React from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import SpinLoader from "../../components/loaders/SpinLoader"
import { SIGNIN } from "../../graphql/mutation"
import { useAuth } from "../../providers/Auth"

export default function LoginForm(props) {
  const { register, handleSubmit, setValue } = useForm()
  const [Login, { loading, data }] = useMutation(SIGNIN)
  const { setAuth } = useAuth()

  const onSubmit = async (data) => {
    const response = await Login({
      variables: {
        username: data.username,
        password: data.password,
      },
    })
    // console.log("response : ", response);
    if (response?.data?.signIn?.token) {
      setAuth(response.data)
    }else{
      toast.error("Incorrect information")
    }
  }

  return (
    <div className="">
      <SpinLoader loading={loading} absolute />
      <div className="login-form-wrap">
        <form onSubmit={handleSubmit(onSubmit)} className="pos-rel">
          <div className="form-group">
            <div className="  ">
              <label htmlFor="email">Email address *</label>
              <input type={"email"} {...register("username")} className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <div className="  ">
              <label htmlFor="password">Password *</label>
              <input type={"password"} {...register("password")} className="form-control" />
            </div>
          </div>

          <button type="submit" className="btn btn-light">
            Login
          </button>
          <a href="https://munkhcashmere.com/en/trade-account-enquiry/" className="ml-small">Register</a>
        </form>
      </div>
    </div>
  )
}
