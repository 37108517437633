import {gql} from "@apollo/client"

export const GET_ME = gql`
  query Me {
    me {
      user {
        id
        status
        email
        createdAt
        profile {
          id
          avatarId
          avatarUrls
          firstname
          lastname
          businessName
          phone
          clientDescription
          businessDescription
          numberOfStores
          specialInstructions
          shippingAddress
          billingAddress
          website

          birthdayYear
          birthdayMonth
          birthdayDay
          about
          gender
          createdAt
          userId
        }
        tempPassword
        folder {
          id
          name
          updatedAt
          createdAt
          userId
          _count {
            FolderPhotos
          }
        }
        folderPhotos {
          id
          createdAt
          folderId
          variantionPhotoId
          variantion {
            id
            main_price
            wholesale_price
            productId
            tags {
              id
              variantionId
              tag {
                id
                type
                name
                code
                description
                order
              }
              tagId
            }
            photos {
              id
              isThumbnail
              mediaImageGenerateds
            }
            product {
              id
              status
              name
              code
              gauge
              ply
              stitch
              size
              fit
              description
              updatedAt
              updatedById
              createdAt
              createdById
            }
          }
        }
        termAccept
      }
    }
  }
`

export const GET_PRODUCT_LIST = gql`
  query GetProductListResponse($skip: Int) {
    GetProductListResponse(skip: $skip) {
      edges {
        cursor
        node {
          id
          status
          name
          description
          updatedAt
          createdAt
          userId
          thumbnailId
          thumbnail {
            id
            fileName
            filePath
            fileFolder
            fileSize
            fileType
            fileExt
            createdAt
            userId
            mediaImageGenerateds {
              id
              path
              generatedID
              fileFolder
              fileExtension
              fileSize
              sizeName
              sizeWidth
              sizeHeight
              forRetina
              mediaId
              createdAt
            }
          }
          photos {
            id
            status
            order
            mediaId
            productId
            media {
              id
              fileName
              filePath
              fileFolder
              fileSize
              fileType
              fileExt
              createdAt
              userId
              mediaImageGenerateds {
                id
                path
                generatedID
                fileFolder
                fileExtension
                fileSize
                sizeName
                sizeWidth
                sizeHeight
                forRetina
                mediaId
                createdAt
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_PRODUCT_DETAIL = gql`
  query GetProductDetail($id: Int!) {
    GetProductDetail(id: $id) {
      id
      status
      name
      code
      gauge
      ply
      stitch
      size
      fit
      description
      updatedAt
      updatedById
      createdAt
      createdById
      variantions {
        id
        main_price
        wholesale_price
        productId
        tags {
          id
          variantionId
          tag {
            id
            type
            name
            code
            description
            order
          }
          tagId
        }
        photos {
          id
          isThumbnail
          mediaImageGenerateds
        }
      }
    }
  }
`
export const GET_VARIANTION = gql`
  query GetProductVariantion($id: Int!) {
    GetProductVariantion(id: $id) {
      id
      main_price
      wholesale_price
      productId
      product_variantion_price
      tags {
        id
        variantionId
        tag {
          id
          type
          name
          code
          description
          order
          group {
            id
            type
            slug
            name
          }
        }
        tagId
      }
      photos {
        id
        isThumbnail
        mediaImageGenerateds
      }
      product {
        id
        status
        name
        code
        gauge
        ply
        stitch
        size
        fit
        description
        updatedAt
        updatedById
        createdAt
        createdById
        variantions {
          id
          main_price
          wholesale_price
          productId
          tags {
            id
            variantionId
            tag {
              id
              type
              name
              code
              description
              order
              group {
                id
                type
                slug
                name
              }
            }
            tagId
          }
          photos {
            id
            isThumbnail
            mediaImageGenerateds
          }
          product {
            id
            status
            name
            code
            gauge
            ply
            stitch
            size
            fit
            description
            updatedAt
            updatedById
            createdAt
            createdById
          }
        }

        product
      }
      next
      prev
    }
  }
`
export const GET_PHOTO_LIST = gql`
  query GetPhotosList($skip: Int, $tagGroupId: Int, $productTypeId: Int, $categoryId: Int, $search: String) {
    GetPhotosList(skip: $skip, tagGroupId: $tagGroupId, productTypeId: $productTypeId, categoryId: $categoryId, search: $search) {
      edges {
        cursor
        node {
          id
          main_price
          wholesale_price
          productId
          photoId
          tags {
            id
            variantionId
            tag {
              id
              type
              name
              code
              description
              order
            }
            tagId
          }
          photos {
            id
            mediaId
            isThumbnail
            mediaImageGenerateds
          }
          product {
            id
            status
            name
            code
            gauge
            ply
            stitch
            size
            fit
            description
            updatedAt
            updatedById
            createdAt
            createdById
          }
          product_variantion_price
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        count
      }
    }
  }
`
export const GET_FOLDER_PHOTO_LIST = gql`
  query GetFolderPhotoList($skip: Int, $folderId: Int) {
    GetFolderPhotoList(skip: $skip, folderId: $folderId) {
      edges {
        cursor
        node {
          id
          main_price
          wholesale_price
          productId
          tags {
            id
            variantionId
            tag {
              id
              type
              name
              code
              description
              order
            }
            tagId
          }
          photos {
            id
            isThumbnail
            mediaImageGenerateds
          }
          product {
            id
            status
            name
            code
            gauge
            ply
            stitch
            size
            fit
            description
            updatedAt
            updatedById
            createdAt
            createdById
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_FOLDER_DETAIL = gql`
  query GetFolderDetail($id: Int!) {
    GetFolderDetail(id: $id) {
      id
      name
      updatedAt
      createdAt
      userId
      FolderPhotos {
        id
        createdAt
        folderId
        variantionPhotoId
        variantion {
          id
          main_price
          wholesale_price
          productId
          tags {
            id
            variantionId
            tag {
              id
              type
              name
              code
              description
              order
            }
            tagId
          }
          photos {
            id
            isThumbnail
            mediaImageGenerateds
          }
          product {
            id
            status
            name
            code
            gauge
            ply
            stitch
            size
            fit
            description
            updatedAt
            updatedById
            createdAt
            createdById
          }
        }
      }
    }
  }
`
export const GET_FOLDER_LIST = gql`
  query GetFolderList($skip: Int) {
    GetFolderList(skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          updatedAt
          createdAt
          userId
          FolderPhotos {
            id
            createdAt
            folderId
            variantionPhotoId
            variantion {
              id
              main_price
              wholesale_price
              productId
              tags {
                id
                variantionId
                tag {
                  id
                  type
                  name
                  code
                  description
                  order
                }
                tagId
              }
              photos {
                id
                isThumbnail
                mediaImageGenerateds
              }
              product {
                id
                status
                name
                code
                gauge
                ply
                stitch
                size
                fit
                description
                updatedAt
                updatedById
                createdAt
                createdById
              }
            }
          }
        }
      }
    }
  }
`
export const GET_REQUEST_LIST = gql`
  query GetRequestList($skip: Int) {
    GetRequestList(skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          status
          updatedAt
          createdAt
          userId
          products {
            id
            createdAt
            productId
            requestId
            userId
            comment
            product {
              id
              status
              name
              code
              gauge
              ply
              stitch
              size
              fit
              description
              thumbnail
              thumbnailId
              updatedAt
              updatedById
              createdAt
              createdById
              variantions {
                id
                main_price
                wholesale_price
                productId
                tags {
                  id
                  variantionId
                  tag {
                    id
                    type
                    name
                    code
                    description
                    order
                  }
                  tagId
                }
                photos {
                  id
                  isThumbnail
                  mediaImageGenerateds
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_REQUEST_DETAIL = gql`
  query GetRequestDetail($id: Int!) {
    GetRequestDetail(id: $id) {
      id
      status
      updatedAt
      createdAt
      userId
      products {
        id
        createdAt
        productId
        requestId
        userId
        comment
        materialId
        material
        product {
          id
          status
          name
          code
          gauge
          ply
          stitch
          size
          fit
          description
          updatedAt
          updatedById
          thumbnail
          thumbnailId
          createdAt
          createdById
          variantions {
            id
            main_price
            wholesale_price
            productId
            tags {
              id
              variantionId
              tag {
                id
                type
                name
                code
                description
                order
              }
              tagId
            }
            photos {
              id
              isThumbnail
              mediaImageGenerateds
            }
          }
        }
      }
    }
  }
`

export const GET_ORDER_LIST = gql`
  query GetOrderList($skip: Int) {
    GetOrderList(skip: $skip) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          status
          updatedAt
          createdAt
          userId
          products {
            id
            createdAt
            productId
            orderId
            userId
            comment
            product {
              id
              status
              name
              code
              gauge
              ply
              stitch
              size
              fit
              description
              thumbnail
              thumbnailId
              updatedAt
              updatedById
              createdAt
              createdById
              variantions {
                id
                main_price
                wholesale_price
                productId
                tags {
                  id
                  variantionId
                  tag {
                    id
                    type
                    name
                    code
                    description
                    order
                  }
                  tagId
                }
                photos {
                  id
                  isThumbnail
                  mediaImageGenerateds
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ORDER_DETAIL = gql`
  query GetOrderDetail($id: Int!) {
    GetOrderDetail(id: $id) {
      id
      status
      updatedAt
      createdAt
      userId
      products {
        id
        createdAt
        productId
        orderId
        userId
        comment
        materialId
        material
        colorId
        color
        sizesqty
        OrderProductsAttribute {
          id
          count
          orderProductsId
          attributeId
        }
        product {
          id
          status
          name
          code
          gauge
          ply
          stitch
          size
          fit
          description
          thumbnail
          thumbnailId
          updatedAt
          updatedById
          createdAt
          createdById
          variantions {
            id
            main_price
            wholesale_price
            productId
            tags {
              id
              variantionId
              tag {
                id
                type
                name
                code
                description
                order
                group {
                  id
                  type
                  slug
                  name
                }
              }
              tagId
            }
            photos {
              id
              isThumbnail
              mediaImageGenerateds
            }
          }
        }
      }
    }
  }
`

export const GET_CATEGORY_LIST = gql`
  query GetCategoryList {
    GetCategoryList {
      id
      type
      slug
      name
      tags {
        id
        type
        name
        code
        description
        order
      }
    }
  }
`
export const GET_BADGES = gql`
  query GetBadges {
    GetBadges
  }
`
export const GET_TAG_GROUPS = gql`
  query GetPublicTagGroups {
    GetPublicTagGroups {
      id
      type
      slug
      name
      tags {
        id
        type
        name
        code
        description
        order
      }
    }
  }
`
export const GET_TAG_HIERARCHY = gql`
  query GetTagHierarchy {
    GetTagHierarchy
  }
`

export const GET_POST_LIST = gql`
  query GetPublicPostList($skip: Int) {
    GetPublicPostList(skip: $skip) {
      edges {
        cursor
        node {
          id
          status
          title
          intro
          excerpt
          content
          thumbnail
          thumbnailId
          updatedAt
          createdAt
          creator
          creatorId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_POST = gql`
  query GetPublicPostDetail($id: Int!) {
    GetPublicPostDetail(id: $id) {
      id
      status
      title
      intro
      excerpt
      content
      thumbnail
      thumbnailId
      updatedAt
      createdAt
      creator
      creatorId
    }
  }
`
export const GET_ACCEPT_TERM = gql`
  query GetAcceptTerm {
    GetAcceptTerm
  }
`
