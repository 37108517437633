import { Outlet } from "react-router-dom"

export default function WideLayout() {
  return (
    <>
      <main>
        <div className="container">
          <div className="main-content withoutsidebar">
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}
