import {useMutation} from "@apollo/client"
import {UPDATE_ORDER_PRODUCT_ATTRIBUTE} from "../graphql/mutation"
import {useInit} from "../providers/Init"
import {useModal} from "../providers/Modal"
import Dropdown from "./Dropdown"
import Modal from "./Modal"
import {useEffect} from "react"

export default function MaterialChooser({material, setMaterial, id, type, product}) {
  // const [search, setSearch] = useState()
  const {materials} = useInit()
  const {modalId, showModal} = useModal()
  // const [UpdateOrderProductAttribute] = useMutation(UPDATE_ORDER_PRODUCT_ATTRIBUTE)

  const choose = async (choosedMaterial) => {
    setMaterial(choosedMaterial)
    showModal("")
    // setSearch("")
  }

  useEffect(() => {
 

    return () => {
      // setMaterial("")
    }
  }, [material])

  // console.log("materials : ", materials)
  // console.log("product : ", product)
  let isSilk = false
  if (product) {
    product.variantions.forEach((v) => {
      v.tags.forEach((tgs) => {
        if (tgs.tag.name == "85% silk, 15% cashmere") {
          isSilk = true
        }
      })
    })
  }

  if (!materials || materials.length === 0) return ""

  let filteredMaterials = materials
  if (isSilk) {
    filteredMaterials = filteredMaterials.filter((d) => d.name === "85% silk, 15% cashmere")
  }else{
    filteredMaterials = filteredMaterials.filter((d) => d.name !== "85% silk, 15% cashmere")
  }
  return (
    <>
      <button className="btn-choose-attr" onClick={() => showModal(`materialModal${id}`)}>
        {material ? (
          <div className="attr-list-item choosed" onClick={() => showModal(`materialModal${id}`)}>
            {/* <div className="attr-thumb"></div> */}
            <div className="">{material.name}</div>
          </div>
        ) : (
          <u>Select material</u>
        )}
      </button>

      <Modal medium id={`materialModal${id}`}>
        <Modal.Body>
          <div className="mb-small d-flex align-items-center justify-content-between">
            <div className="fs-20 ">Choose material</div>
            {/* <div className="">
              <input type="text" name="" id="" className="form-control" placeholder="Search material" />
            </div> */}
          </div>
          <div className="attr-list-group">
            {filteredMaterials.map((m, k) => {
              return (
                <div className="attr-list-item" key={k} onClick={() => choose(m)}>
                  {/* <div className="attr-thumb"></div> */}
                  <div className="attr-name">{m.name}</div>
                </div>
              )
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
