import {useMutation} from "@apollo/client"
import { useEffect } from "react"
import {useForm} from "react-hook-form"
import toast from "react-hot-toast"
import Modal from "../../../components/Modal"
import {CREATE_FOLDER} from "../../../graphql/mutation"
import { useInit } from "../../../providers/Init"
import {useModal} from "../../../providers/Modal"

export default function CreateFolderModal({photoId}) {
  const {register, handleSubmit, setValue, setFocus} = useForm()
  const {setLatestSavedFolder} = useInit()
  const [CreateFolder, {loading, data}] = useMutation(CREATE_FOLDER, {
    refetchQueries: ["Me"],
  })
  const {modalId, showModal} = useModal()

  const onSubmit = async (data) => {
    const response = await CreateFolder({
      variables: {
        name: data.name,
        photoIds: [photoId],
      },
    })
    if(response?.data?.CreateFolder){
      setLatestSavedFolder(response.data.CreateFolder)
      showModal("")
      toast.success("Saved")
    }
  }
  useEffect(() => {
    setFocus("name");
  }, [setFocus, modalId]);

  return (
    <>
      <Modal id={`createFolderModal${photoId}`} darkBackdrop>
        <Modal.Body>
          <div className="fs-20 mb-small">Create folder</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="  ">
                <label htmlFor="name">Folder name</label>
                <input type={"text"} {...register("name")} className="form-control" />
              </div>
            </div>

            <button type="submit" className="btn btn-light">
              Save
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
