import {useMutation} from "@apollo/client"
import React, {useState} from "react"
import {toast} from "react-hot-toast"
import SpinLoader from "../../../components/loaders/SpinLoader"
import {ADD_PRODUCT_TO_REQUEST} from "../../../graphql/mutation"

export default function AddToRequestButton({productId}) {
  const [minLoad, setMinLoad] = useState(false)
  const [AddToRequest, {loading, data}] = useMutation(ADD_PRODUCT_TO_REQUEST, {
    variables: {
      productId: productId,
    },
    refetchQueries: ["GetBadges"],
    onCompleted: () => {
      toast.success("Added to your price quotation")
    },
  })

  return (
    <button className="btn btn-light " onClick={AddToRequest} disabled={loading}>
      <div className="d-flex align-items-center justify-content-center">
        <SpinLoader loading={loading} delay={500} small /> Add to price quotation
      </div>
    </button>
  )
}
