import React, {createContext, useState} from "react"
import Cookies from "universal-cookie"
import {GET_ME} from "../graphql/query"
import {useQuery} from "@apollo/client"
import toast from "react-hot-toast"
import SpinLoader from "../components/loaders/SpinLoader"

const cookies = new Cookies()

export const AuthContext = createContext()

function AuthProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [me, setMe] = useState()

  const {loading} = useQuery(GET_ME, {
    onCompleted: (data) => {
      if (data?.me) {
        setMe(data.me)
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)

        removeAuth()
      }
    },
    onError: (error) => {
      console.log("error ", error);
      setIsAuthenticated(false)

      // removeAuth()
    },
  })

  const setAuth = (data) => {
    // console.log("set auth ", data)
    if (data?.signIn?.token) {
      setMe(data?.signIn)
      setIsAuthenticated(true)
      cookies.set("token", data?.signIn?.token, {path: "/"})
      toast.success("Welcome")
    }
  }

  const removeAuth = () => {
    cookies.remove("token", {path: "/"})
    setMe("")
    setIsAuthenticated(false)
  }
  if (loading)
    return (
      <div className="minvh-100 d-flex w-100 align-items-center justify-content-center">
        <div className="pos-rel h-100">
          Authorizing...
          {/* <SpinLoader loading={loading} /> */}
        </div>
      </div>
    )

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        me,
        setMe,
        setAuth,
        removeAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => React.useContext(AuthContext)

export {AuthProvider, useAuth}
