import React from "react"
import {useQuery} from "@apollo/client"
import {Link, useParams} from "react-router-dom"
import {GET_POST} from "../../graphql/query"
import PhotoItem from "../../components/PhotoItem"
import SpinLoader from "../../components/loaders/SpinLoader"
import PhotoSkelotonLoader from "../../components/loaders/PhotoSkelotonLoader"
import PhotoGallery from "../category/components/PhotoGallery"
import edjsParser from "editorjs-parser"

export default function NewsDetailScreen() {
  const params = useParams()
  const [news, setNews] = React.useState()

  const {loading} = useQuery(GET_POST, {
    variables: {
      id: parseInt(params.id),
    },
    onCompleted: (data) => {
      setNews(data.GetPublicPostDetail)
    },
  })

  const customParsers = {
    heading: function (data, config) {
      if (data.level === 2) {
        return `<h2>${data.text}</h2>`
      }

      return `<h3>${data.text}</h3>`
    },
    delimiter: function (data, config) {
      return `<hr class='divider' />`
    },
    image: function (data, config) {
      let stretchCls = "",
        bgCls = ""
      if (data.stretched) stretchCls = "stretch-fig"
      if (data.withBackground) bgCls = "withBg"
      return `<figure class="fig-img ${stretchCls} ${bgCls}">
        <img 
        data-large="${data.file?.url}"
        blur-type="img"
        class="img blurry-load w-100f" src="${data.file?.url}" 
        alt="${data.caption}" >
        ${data.caption ? `<figcaption class='fig-cap'>${data.caption}</figcaption>` : ``}
      </figure>`
    },
  }
  const isJson = (text) => {
    try {
      JSON.parse(text)
    } catch (error) {
      return false
    }
    return true
  }

  const parser = new edjsParser(undefined, customParsers)
  const markup = isJson(news?.content) ? parser.parse(JSON.parse(news.content)) : news?.content

  return (
    <>
      <div className="">
        <Link to="/news">← Back to news</Link>
      </div>

      <div className="news-wrap ">
        <div className="mb-medium">
          <h1 className="news-title">{news?.title} </h1>
        </div>

        <div className="news-content" dangerouslySetInnerHTML={{__html: markup}}></div>
      </div>
    </>
  )
}
