module.exports = (urlArr, size = "PRODUCT_MEDIUM") => {
  let isRetina = false
  if (window.matchMedia) {
    var mq = window.matchMedia(
      "only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)"
    )
    isRetina = (mq && mq.matches) || window.devicePixelRatio > 1
	}

  const arr = urlArr.filter((g) => g.sizeName === size && g.forRetina == isRetina)
  if (arr?.length > 0) {
    return arr[0].path
  } else {
    const arr2 = urlArr.filter((g) => g.sizeName === size)
    if (arr2?.length > 0) {
      return arr2[0].path
    } else {
      return urlArr[0].path
    }
  }
  
  return ""
}
