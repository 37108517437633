import {useMutation} from "@apollo/client"
import React, {useEffect, useRef} from "react"
import toast from "react-hot-toast"
import {Link, useLocation} from "react-router-dom"
import Ratio23 from "../assets/images/ratio2-3.png"
import {CREATE_FOLDER_PHOTO, DELETE_FOLDER_PHOTO} from "../graphql/mutation"
import CreateFolderButton from "../modules/folder/components/CreateFolderButton"
import CreateFolderModal from "../modules/folder/components/CreateFolderModal"
import {useAuth} from "../providers/Auth"
import {useInit} from "../providers/Init"
import {useModal} from "../providers/Modal"
import getGeneratedThumbPath from "../utils/getGeneratedThumbPath"
import Dropdown from "./Dropdown"

export default function PhotoItem({photo, savedOn: alreadySavedOn, index, openGallery, preRatio23, prefix, removeFromId, rawPhoto = false, backParams}) {
  const location = useLocation()
  const {me} = useAuth()
  const {latestSavedFolder, setLatestSavedFolder} = useInit()
  const {modalId, showModal} = useModal()

  const [SavePhoto] = useMutation(CREATE_FOLDER_PHOTO, {
    refetchQueries: ["Me"],
  })
  const [DeletePhoto] = useMutation(DELETE_FOLDER_PHOTO, {
    refetchQueries: ["GetFolderDetail", "GetFolderPhotoList", "Me"],
  })
  const [savedOn, setSaved] = React.useState(alreadySavedOn ? true : false)

  const onSubmit = async (f, photoId) => {
    const response = await SavePhoto({
      variables: {
        folderId: f.id,
        photoId: photoId,
      },
    })
    setLatestSavedFolder(f)
    setSaved(true)
    toast.success("Saved")
  }
  const onDelete = async (id, photoId) => {
    const response = await DeletePhoto({
      variables: {
        folderId: parseInt(id),
        photoId: photoId,
      },
    })
  }
  let thumbUrl, variantionPhotoId, thumbArr, thumbLargeUrl
  if (rawPhoto) {
    thumbUrl = photo.photos[0]?.photo?.mediaImageGenerateds[0]?.path ? process.env.REACT_APP_API_URL + photo.photos[0]?.photo?.mediaImageGenerateds[0]?.path : ""
    variantionPhotoId = photo.photos[0].id
  } else {
    thumbUrl = photo.photos[0]?.mediaImageGenerateds[0]?.path ? process.env.REACT_APP_API_URL + photo.photos[0]?.mediaImageGenerateds[0]?.path : ""
    variantionPhotoId = photo.photos[0].id
    if (photo.photos?.length > 0 && photo.photoId) {
      thumbArr = photo.photos.filter((p) => p.mediaId === photo.photoId)
      // console.log("thumbArr[0]", thumbArr[0].mediaImageGenerateds)
      thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(thumbArr[0].mediaImageGenerateds, "PRODUCT_MEDIUM")
      thumbLargeUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(thumbArr[0].mediaImageGenerateds, "PRODUCT_LARGE")
      variantionPhotoId = thumbArr[0].id
    }
  }

  let price
  if (photo.product_variantion_price?.length > 0) {
    price = photo.product_variantion_price[0]
  }
  let pb = backParams ? "?bp=" + backParams : ""

  let product_path = prefix ? `${prefix}/${photo.id}${pb}` : `/product/${photo.id}${pb}`
  // console.log('location:', location);

  let currency

  if (price?.currencyId === "EUR") {
    currency = "€"
  } else if (price?.currencyId === "USD") {
    currency = "$"
  } else if (price?.currencyId === "RUB") {
    currency = "₽"
  }

  return (
    <>
      <div className="list-item">
        <div className="product">
          <Link className="full-link" to={product_path} state={{prevPath: location.pathname + location.search}}></Link>
          {/* <Link
            className="full-link"
            to={prefix ? `${prefix}/${photo.id}${pb}` : `/product/${photo.id}${pb}`}
          ></Link> */}
          <div className="thumb with-border" style={{backgroundImage: `url('${thumbUrl}')`}}>
            <img src={preRatio23 || Ratio23} alt="" />
            <div className="thumb-action">
              <div className="d-flex align-items-center justify-content-between w-100 thumb-action-content">
                {removeFromId ? (
                  <>
                    <div className="btn btn-dark btn-small" onClick={() => onDelete(removeFromId, variantionPhotoId)}>
                      Remove photo
                    </div>
                  </>
                ) : (
                  <>
                    <Dropdown className={"folder-dropdown"}>
                      <Dropdown.Toggle>
                        <Link to="#_" className="folder-dropdown-selected">
                          <div className="">{latestSavedFolder?.name}</div>
                          {latestSavedFolder?.name && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="5" viewBox="0 0 12 5">
                              <path id="Polygon_2" data-name="Polygon 2" d="M6,0l6,5H0Z" transform="translate(12 5) rotate(180)" fill="#fff" />
                            </svg>
                          )}
                        </Link>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="">
                        {me.user?.folder?.length > 0 &&
                          me.user.folder.map((f, key) => {
                            return (
                              <Dropdown.ListItem key={key}>
                                <Link to="#_" className="folder-dropdown-item">
                                  <div className="">{f.name}</div>
                                  <div className="btn btn-dark btn-small" onClick={() => onSubmit(f, variantionPhotoId)}>
                                    Save to folder
                                  </div>
                                </Link>
                              </Dropdown.ListItem>
                            )
                          })}

                        <Dropdown.Divider></Dropdown.Divider>

                        <CreateFolderButton photoId={variantionPhotoId} />
                      </Dropdown.Menu>
                    </Dropdown>

                    {me.user?.folder?.length > 0 ? (
                      <div className="btn btn-dark btn-small" onClick={() => onSubmit(latestSavedFolder, variantionPhotoId)}>
                        Save to folder
                      </div>
                    ) : (
                      <div className="btn btn-dark btn-small" onClick={() => showModal(`createFolderModal${variantionPhotoId}`)}>
                        Save to folder
                      </div>
                    )}
                  </>
                )}
              </div>
              <a href={thumbLargeUrl || thumbUrl} download target={"_blank"} className="photo-download-btn d-flex align-items-center justify-content-center">
                Download
              </a>
              <div className="photo-zoom-btn d-flex align-items-center justify-content-center" onClick={() => openGallery(index)}>
                Zoom
              </div>
            </div>
          </div>
          <div className="desc">
            <div className="d-flex justify-content-between ">
              <div>
                <b>{photo.product.name || photo.product.code}</b>
              </div>
              <div className="">{photo.product.code}</div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="mr-small">{photo.product.gauge}</div>
              <div className="mr-small">{photo.product.ply}</div>
              <div>{photo.product.stitch}</div>
            </div>
            {price && (
              <div className="d-flex justify-content-start">
                {price.sale_price > 0 ? (
                  <>
                    <span className="price has-sale">
                      {currency} {price.main_price}
                    </span>
                    <span className=" " style={{marginLeft: "15px"}}>
                      {currency} {price.sale_price}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="price">
                      {currency} {price.main_price}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateFolderModal photoId={variantionPhotoId} />
    </>
  )
}
