import { Navigate } from "react-router-dom"
import LoginScreen from "../modules/auth/LoginScreen"
import { useAuth } from "../providers/Auth"

export default function Login() {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <LoginScreen />
}
