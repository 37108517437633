import React from "react"
import {useQuery} from "@apollo/client"
import {Link, useParams} from "react-router-dom"
import {GET_FOLDER_DETAIL, GET_FOLDER_PHOTO_LIST} from "../../graphql/query"
import useInfiniteScroll from "../../utils/InfiniteScroll"
import PhotoItem from "../../components/PhotoItem"
import SpinLoader from "../../components/loaders/SpinLoader"
import PhotoSkelotonLoader from "../../components/loaders/PhotoSkelotonLoader"
import PhotoGallery from "../category/components/PhotoGallery"
import getGeneratedThumbPath from "../../utils/getGeneratedThumbPath"

export default function FolderDetailScreen() {
  const params = useParams()
  const [list, setList] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState()
  const [folder, setFolder] = React.useState()
  const [galleryItems, setGalleryItems] = React.useState([])
  const [openIndex, setGalleryOpenIndex] = React.useState()

  const {loading: detailLoading} = useQuery(GET_FOLDER_DETAIL, {
    variables: {
      id: parseInt(params.id),
    },
    onCompleted: (data) => {
      setFolder(data.GetFolderDetail)
    },
  })
  const {loading, fetchMore} = useQuery(GET_FOLDER_PHOTO_LIST, {
    variables: {
      folderId: parseInt(params.id),
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setList(data.GetFolderPhotoList.edges.map((edge) => edge.node))
      setPageInfo(data.GetFolderPhotoList.pageInfo)
      setGalleryItems(
        data.GetFolderPhotoList.edges
          .filter((edge) => edge.node.photos?.length > 0)
          .map((edge) => {
            let thumbUrl = edge.node.photos[0]?.mediaImageGenerateds[0]?.path ? process.env.REACT_APP_API_URL + edge.node.photos[0]?.mediaImageGenerateds[0]?.path : ""
            if (edge.node.photos?.length > 0 && edge.node.photoId) {
              let thumbArr = edge.node.photos.filter((p) => p.mediaId === edge.node.photoId)

              thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(thumbArr[0].mediaImageGenerateds, "PRODUCT_LARGE")
            }

            return {
              id: edge.node.id,
              size: "1400-933",
              src: thumbUrl,
              thumb: thumbUrl,
              subHtml: `<div class="lightGallery-captions">
                    <h4>${edge.node.product.name}</h4>
                </div>`,
            }
          })
      )
    },
  })
  const onMore = async () => {
    if (pageInfo.hasNextPage) {
      const moreresult = await fetchMore({
        variables: {
          skip: pageInfo.endCursor,
        },
      })
      setList(list.concat(moreresult.data.GetFolderPhotoList.edges.map((edge) => edge.node)))
      setPageInfo(moreresult.data.GetFolderPhotoList.pageInfo)
      setGalleryItems(
        moreresult.data.GetFolderPhotoList.edges
          .filter((edge) => edge.node.photos?.length > 0)
          .map((edge) => {
            let thumbUrl = edge.node.photos[0]?.mediaImageGenerateds[0]?.path ? process.env.REACT_APP_API_URL + edge.node.photos[0]?.mediaImageGenerateds[0]?.path : ""
            if (edge.node.photos?.length > 0 && edge.node.photoId) {
              let thumbArr = edge.node.photos.filter((p) => p.mediaId === edge.node.photoId)

              thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(thumbArr[0].mediaImageGenerateds, "PRODUCT_LARGE")
            }

            return {
              id: edge.node.id,
              size: "1400-933",
              src: thumbUrl,
              thumb: thumbUrl,
              subHtml: `<div class="lightGallery-captions">
                    <h4>${edge.node.product.name}</h4>
                </div>`,
            }
          })
      )
    }
  }
  const [lastElementRef] = useInfiniteScroll(pageInfo?.hasNextPage ? () => onMore() : () => {}, loading)

  const openGallery = (index) => {
    setGalleryOpenIndex(index)
  }
  React.useEffect(() => {
    return () => {
      setList([])
      setGalleryItems([])
      setGalleryOpenIndex("")
    }
  }, [params.id])

  if (loading || detailLoading)
    return (
      <div className="photos-wrap">
        <PhotoSkelotonLoader repeat={4} />
      </div>
    )
  if (!folder && params.id && params.id !== "all") return <div className="text-center w-100 py-5">No products</div>

  return (
    <>
      <div className="">
        <Link to="/folders">← Back to folders</Link>
        <div className="mb-medium">
          <h1>{folder?.name || "All photos"} </h1>
        </div>
      </div>

      <div className="photos-wrap ">
        {list && list.length > 0 && (
          <>
            <PhotoGallery galleryItems={galleryItems} openIndex={openIndex} />
            {list?.map((photo, key) => {
              return <PhotoItem photo={photo} removeFromId={params.id} key={key} index={key} openGallery={openGallery} />
            })}
            {loading && <PhotoSkelotonLoader repeat={4} />}
            {pageInfo?.hasNextPage && <div className="lastDiv" ref={lastElementRef}></div>}
          </>
        )}
        {list.length === 0 && !loading && <div className="text-center w-100 py-5">No photos found</div>}
      </div>
    </>
  )
}
