import {gql} from "@apollo/client"

export const SIGNIN = gql`
  mutation SignIn($username: String!, $password: String!) {
    signIn(username: $username, password: $password) {
      token
      user {
        id
        status
        email
        createdAt
        profile {
          id
          avatarId
          avatarUrls
          firstname
          lastname
          businessName
          phone
          clientDescription
          businessDescription
          numberOfStores
          specialInstructions
          shippingAddress
          billingAddress
          website
          birthdayYear
          birthdayMonth
          birthdayDay
          about
          gender
          createdAt
          userId
        }
        tempPassword
        termAccept
        folder {
          id
          name
          updatedAt
          createdAt
          userId
          _count {
            FolderPhotos
          }
        }
        folderPhotos {
          id
          createdAt
          folderId
          variantionPhotoId
          variantion {
            id
            main_price
            wholesale_price
            productId
            tags {
              id
              variantionId
              tag {
                id
                type
                name
                code
                description
                order
              }
              tagId
            }
            photos {
              id
              isThumbnail
              mediaImageGenerateds
            }
            product {
              id
              status
              name
              code
              gauge
              ply
              stitch
              size
              fit
              description
              updatedAt
              updatedById
              createdAt
              createdById
            }
          }
        }
      }
    }
  }
`
export const CREATE_FOLDER_PHOTO = gql`
  mutation CreateFolderPhoto($photoId: Int!, $folderId: Int!) {
    CreateFolderPhoto(photoId: $photoId, folderId: $folderId)
  }
`
export const DELETE_FOLDER_PHOTO = gql`
  mutation DeleteFolderPhoto($photoId: Int!, $folderId: Int!) {
    DeleteFolderPhoto(photoId: $photoId, folderId: $folderId)
  }
`
export const CREATE_FOLDER = gql`
  mutation CreateFolder($name: String!, $photoIds: [Int]) {
    CreateFolder(name: $name, photoIds: $photoIds) {
      id
      name
      updatedAt
      createdAt
      userId
      # photos: [FolderPhotos]
      # _count: FolderCount
    }
  }
`
export const UPDATE_FOLDER = gql`
  mutation UpdateFolder($name: String!, $id: Int!) {
    UpdateFolder(name: $name, id: $id) {
      id
      name
      updatedAt
      createdAt
      userId
      # photos: [FolderPhotos]
      # _count: FolderCount
    }
  }
`
export const DELETE_FOLDER = gql`
  mutation DeleteFolder($id: Int!) {
    DeleteFolder(id: $id)
  }
`
export const ADD_PRODUCT_TO_REQUEST = gql`
  mutation AddProductToRequest($productId: Int!) {
    AddProductToRequest(productId: $productId)
  }
`
export const REMOVE_PRODUCT_FROM_REQUEST = gql`
  mutation RemoveProductToRequest($requestProductId: Int!) {
    RemoveProductToRequest(requestProductId: $requestProductId)
  }
`

export const ADD_PRODUCT_TO_ORDER = gql`
  mutation AddProductToOrder($productId: Int!) {
    AddProductToOrder(productId: $productId)
  }
`
export const REMOVE_PRODUCT_FROM_ORDER = gql`
  mutation RemoveProductToOrder($orderProductId: Int!) {
    RemoveProductToOrder(orderProductId: $orderProductId)
  }
`
export const UPDATE_USER_INFO = gql`
  mutation UpdateInfo(
    $firstname: String!
    $lastname: String!
    $businessName: String!
    $phone: String!
    $clientDescription: String!
    $businessDescription: String!
    $numberOfStores: String
    $specialInstructions: String
    $shippingAddress: String
    $billingAddress: String
    $website: String
  ) {
    UpdateInfo(
      firstname: $firstname
      lastname: $lastname
      businessName: $businessName
      phone: $phone
      clientDescription: $clientDescription
      businessDescription: $businessDescription
      numberOfStores: $numberOfStores
      specialInstructions: $specialInstructions
      shippingAddress: $shippingAddress
      billingAddress: $billingAddress
      website: $website
    ) {
      id
      status
      email
      createdAt
      profile {
        id
        avatarId
        avatarUrls
        firstname
        lastname
        businessName
        phone
        clientDescription
        businessDescription
        numberOfStores
        specialInstructions
        shippingAddress
        billingAddress
        website
        birthdayYear
        birthdayMonth
        birthdayDay
        about
        gender
        createdAt
        userId
      }
      tempPassword
      folder {
        id
        name
        updatedAt
        createdAt
        userId
        _count {
          FolderPhotos
        }
      }
      folderPhotos {
        id
        createdAt
        folderId
        variantionPhotoId
        variantion {
          id
          main_price
          wholesale_price
          productId
          tags {
            id
            variantionId
            tag {
              id
              type
              name
              code
              description
              order
            }
            tagId
          }
          photos {
            id
            isThumbnail
            mediaImageGenerateds
          }
          product {
            id
            status
            name
            code
            gauge
            ply
            stitch
            size
            fit
            description
            updatedAt
            updatedById
            createdAt
            createdById
          }
        }
      }
    }
  }
`
export const SEND_REQUEST = gql`
  mutation SendRequest($id: Int!) {
    SendRequest(id: $id)
  }
`
export const SEND_ORDER = gql`
  mutation SendOrder($id: Int!) {
    SendOrder(id: $id)
  }
`
export const UPDATE_ORDER_PRODUCT_COMMENT = gql`
  mutation UpdateOrderProductComment($orderProductId: Int!, $comment: String) {
    UpdateOrderProductComment(orderProductId: $orderProductId, comment: $comment)
  }
`
export const UPDATE_REQUEST_PRODUCT_COMMENT = gql`
  mutation UpdateRequestProductComment($requestProductId: Int!, $comment: String) {
    UpdateRequestProductComment(requestProductId: $requestProductId, comment: $comment)
  }
`
export const UPDATE_REQUEST_PRODUCT_COPY = gql`
  mutation UpdateRequestProductCopy($requestProductId: Int!) {
    UpdateRequestProductCopy(requestProductId: $requestProductId)
  }
`
export const UPDATE_ORDER_PRODUCT_COPY = gql`
  mutation UpdateOrderProductCopy($orderProductId: Int!) {
    UpdateOrderProductCopy(orderProductId: $orderProductId)
  }
`
export const UPDATE_ORDER_PRODUCT_ATTRIBUTE = gql`
  mutation UpdateOrderProductAttribute($orderProductId: Int!, $attributeId: Int!, $count: Int!) {
    UpdateOrderProductAttribute(orderProductId: $orderProductId, attributeId: $attributeId, count: $count) {
      id
      count
      orderProductsId
      attributeId
    }
  }
`
export const CLEAR_ORDER_PRODUCTS = gql`
  mutation ClearOrderProducts($orderId: Int!) {
    ClearOrderProducts(orderId: $orderId)
  }
`
export const CLEAR_REQUEST_PRODUCTS = gql`
  mutation ClearRequestProducts($requestId: Int!) {
    ClearRequestProducts(requestId: $requestId)
  }
`
export const UPDATE_REQUEST_PRODUCT = gql`
  mutation UpdateRequestProduct($requestProductId: Int!, $comment: String, $materialId: Int) {
    UpdateRequestProduct(requestProductId: $requestProductId, comment: $comment, materialId: $materialId)
  }
`
export const UPDATE_ORDER_PRODUCT = gql`
  mutation UpdateOrderProduct($orderProductId: Int!, $comment: String, $materialId: Int, $colorId: Int, $orderQty: JSON) {
    UpdateOrderProduct(orderProductId: $orderProductId, comment: $comment, materialId: $materialId, colorId: $colorId, orderQty: $orderQty)
  }
`


export const DELETE_ORDER = gql`
  mutation DeleteOrder($id: Int!) {
    DeleteOrder(id: $id)
  }
`
export const DELETE_REQUEST = gql`
  mutation DeleteRequest($id: Int!) {
    DeleteRequest(id: $id)
  }
`


export const ACCEPT_TERM = gql`
  mutation AcceptTerm($id: Int!, $accept: Boolean!) {
    AcceptTerm(id: $id, accept: $accept)
  }
`