import React, {createContext, useEffect, useState} from "react"
import {GET_TAG_HIERARCHY, GET_BADGES, GET_CATEGORY_LIST} from "../graphql/query"
import {useLazyQuery, useQuery} from "@apollo/client"
import SpinLoader from "../components/loaders/SpinLoader"
import {useAuth} from "./Auth"

export const InitContext = createContext()

function InitProvider(props) {
  const {me, isAuthenticated} = useAuth()
  const [colors, setColors] = useState(undefined)
  const [materials, setMaterials] = useState(undefined)
  const [sizes, setSizes] = useState(undefined)
  const [badges, setBadges] = useState()
  const [categories, setCategoryList] = useState()
  const [latestSavedFolder, setLatestSavedFolder] = useState(me?.user?.folder.length > 0 ? me?.user?.folder[0] : null)

  const {loading: attrLoading} = useQuery(GET_TAG_HIERARCHY, {
    onCompleted: (data) => {
      const mats = data.GetTagHierarchy.filter((i) => i.group.slug === "material" && i.group.type === "ATTRIBUTE" && i.tagChildrens.length > 0)
      if (mats.length > 0) setMaterials(mats)

      const cls = data.GetTagHierarchy.filter((i) => i.group.slug === "color" && i.group.type === "ATTRIBUTE" && i.tagChildrens.length > 0)
      if (cls.length > 0) setColors(cls)
    },
  })
  const [GetCategories, {loading: categoryLoading}] = useLazyQuery(GET_CATEGORY_LIST, {
    onCompleted: (data) => {
      setCategoryList(data.GetCategoryList)
    },
  })
  const [GetBadges, {loading: badgesLoading}] = useLazyQuery(GET_BADGES, {
    onCompleted: (data) => {
      setBadges(data.GetBadges)
    },
  })

  const fetchData = async () => {
    await GetCategories()
    await GetBadges()
  }
  useEffect(() => {
    fetchData()

    return () => {}
  }, [isAuthenticated])

  useEffect(() => {
    return () => {}
  }, [categories])

  if (me && (categoryLoading || !categories))
    return (
      <div className="minvh-100 d-flex w-100 align-items-center justify-content-center">
        <div className="pos-rel h-100">
          <SpinLoader loading={true} />
        </div>
      </div>
    )

  // console.log("material: ", materials);
  return (
    <InitContext.Provider
      value={{
        colors,
        materials,
        sizes,
        badges,
        categories,
        latestSavedFolder,
        setLatestSavedFolder,
      }}
    >
      {props.children}
    </InitContext.Provider>
  )
}

const useInit = () => React.useContext(InitContext)

export {InitProvider, useInit}
