import React, { useContext, useEffect, useState } from "react"

const ModalContext = React.createContext()

const ModalProvider = (props) => {
  const [modalId, showModal] = useState("")
  useEffect(() => {
    if (!modalId || modalId === "" || modalId === null) {
      document.body.classList.remove("noscroll")
    }
    // return () => {
    //   document.body.classList.remove("noscroll")
    // }
  }, [modalId, showModal])

  return (
    <ModalContext.Provider
      value={{
        modalId: modalId,
        showModal: showModal,
      }}
    >
      {props.children}
      <div id="modalRoot" className="modal-root"></div>
    </ModalContext.Provider>
  )
}

const useModal = () => useContext(ModalContext)

export { ModalProvider, useModal }
