import {useMutation} from "@apollo/client"
import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import {REMOVE_PRODUCT_FROM_ORDER, UPDATE_ORDER_PRODUCT, UPDATE_ORDER_PRODUCT_COMMENT, UPDATE_ORDER_PRODUCT_COPY} from "../../../graphql/mutation"
import {useModal} from "../../../providers/Modal"
import {useInit} from "../../../providers/Init"
import Modal from "../../../components/Modal"
import {useForm} from "react-hook-form"
import {toast} from "react-hot-toast"
import ColorChooser from "../../../components/ColorChooser"
import MaterialChooser from "../../../components/MaterialChooser"
import SizeChooser from "../../../components/SizeChooser"
import getGeneratedThumbPath from "../../../utils/getGeneratedThumbPath"
import SpinLoader from "../../../components/loaders/SpinLoader"

export default function ProductOrderRow({rp, hideAction = false, refetch}) {
  // const [comment, setComment] = React.useState(rp.comment)
  // const [material, setMaterial] = React.useState(rp.material)
  // const [color, setColor] = React.useState(rp.color)
  // const [orderQty, setOrderQty] = React.useState(rp.sizesqty || [])

  // console.log(rp.product.name, "===============================");
  // console.log("RP material ",rp.material);
  // console.log("material ",material);
  const {register, handleSubmit, setValue, reset, setFocus} = useForm({
    defaultValues: {
      comment: rp.comment,
    },
  })
  const {showModal, modalId} = useModal()

  const [RemoveProduct, {loading: removeLoading, data}] = useMutation(REMOVE_PRODUCT_FROM_ORDER, {
    variables: {
      orderProductId: rp.id,
    },
    onCompleted: () => {
      toast.success("Removed")
      // setComment("")
      refetch()
    },
  })
  const [UpdateOrderProduct, {loading: updateLoading, data: updateData}] = useMutation(UPDATE_ORDER_PRODUCT, {})

  const [CopyOrderProduct, {loading: copyLoading}] = useMutation(UPDATE_ORDER_PRODUCT_COPY, {})

  const submitComment = async (data) => {
    const response = await UpdateOrderProduct({
      variables: {
        orderProductId: rp.id,
        comment: data.comment,
        materialId: rp.material?.id,
        orderQty: rp.sizesqty,
      },
      // refetchQueries: ["GetOrderDetail"],
      onCompleted: () => {
        refetch()
      },
    })
    // setComment(data.comment || comment)
    showModal("")
    toast.success("Saved")
  }
  const changeMaterial = async (mat) => {
    const response = await UpdateOrderProduct({
      variables: {
        orderProductId: rp.id,
        comment: rp.comment,
        materialId: mat.id,
        colorId: null,
        orderQty: rp.sizesqty,
      },
      // refetchQueries: ["GetOrderDetail"],
      onCompleted: () => {
        refetch()
      },
    })
    // setMaterial(mat)
    // setColor("")
    toast.success("Saved")
  }
  const changeColor = async (mat) => {
    const response = await UpdateOrderProduct({
      variables: {
        orderProductId: rp.id,
        comment: rp.comment,
        materialId: rp.material.id,
        colorId: mat.id,
        orderQty: rp.sizesqty,
      },
      // refetchQueries: ["GetOrderDetail"],
      onCompleted: () => {
        refetch()
      },
    })
    // setColor(mat)
    toast.success("Saved")
  }
  const changeSizes = async ({name, qty}) => {
    let tmp
    if (rp.sizesqty) console.log("rp.sizesqty? ", rp.sizesqty)
    if (rp.sizesqty?.filter((q) => q.name === name).length > 0) {
      //Find index of specific object using findIndex method.
      const objIndex = rp.sizesqty.findIndex((q) => q.name === name)
      rp.sizesqty[objIndex].qty = qty

      // setOrderQty([...orderQty])
      tmp = [...rp.sizesqty]
    } else {
      if (rp.sizesqty) {
        tmp = [...rp.sizesqty, {name, qty}]
      } else {
        tmp = [{name, qty}]
      }
      // setOrderQty([...orderQty, {name, qty}])
    }
    // console.log("tmp ", tmp)

    const response = await UpdateOrderProduct({
      variables: {
        orderProductId: rp.id,
        comment: rp.comment,
        materialId: rp.material?.id,
        colorId: rp.color?.id,
        orderQty: tmp,
      },
      // refetchQueries: ["GetOrderDetail"],
      onCompleted: () => {
        refetch()
      },
    })
    toast.success("Saved")
  }

  const submitCopy = async () => {
    const response = await CopyOrderProduct({
      variables: {
        orderProductId: rp.id,
      },
      // refetchQueries: ["GetOrderDetail"],
      onCompleted: () => {
        refetch()
      },
    })
    toast.success("Duplicated")
  }

  let sizes = []
  if (rp.product.size == "S-2XL") {
    sizes = [
      {name: "S", qty: 0},
      {name: "M", qty: 0},
      {name: "L", qty: 0},
      {name: "XL", qty: 0},
      {name: "XXL", qty: 0},
    ]
  } else if (rp.product.size == "One size") {
    sizes = [{name: "One size", qty: 0}]
  } else if (rp.product.size == "S-M, L-XL, 2XL-3XL") {
    sizes = [
      {name: "S-M", qty: 0},
      {name: "L-XL", qty: 0},
      {name: "2XL-3XL", qty: 0},
    ]
  } else if (rp.product.size == "Women, Men" || rp.product.size == "Women,Men") {
    sizes = [
      {name: "Women", qty: 0},
      {name: "Men", qty: 0},
    ]
  } else {
    let throwSizes = [],
      itsThrow = false

    rp.product.variantions.forEach((v) => {
      let isthrow = v.tags?.filter((ts) => ts.tag.name.toLowerCase() == "throw")
      if (isthrow?.length > 0) {
        itsThrow = true
      }

      let d = v.tags?.filter((ts) => ts.tag.group.slug == "size").map((ts) => ts.tag.name)

      if (d?.length > 0) {
        if (throwSizes?.filter((h) => h.name === d[0])?.length == 0) {
          throwSizes.push({name: d[0], qty: 0})
        }
      }
    })

    if (itsThrow) {
      sizes = throwSizes
    } else {
      sizes = [{name: rp.product.size, qty: 0}]
    }
  }
  // console.log("rp.product.size : ", rp.product)

  React.useEffect(() => {
    if (modalId) {
      setFocus("comment")
      reset({
        comment: rp.comment,
      })
    }
    return () => {}
  }, [modalId, rp])

  React.useEffect(() => {
    return () => {
      // setColor("")
      // setComment("")
      // setMaterial("")
      // setOrderQty([])
    }
  }, [rp])

  // if (data?.RemoveProductToOrder) return null

  if (!rp?.product) return ""

  let thumbUrl = ""
  if (rp.product.thumbnail) {
    thumbUrl = process.env.REACT_APP_API_URL + getGeneratedThumbPath(rp.product?.thumbnail.mediaImageGenerateds, "PRODUCT_MEDIUM")
  }

  return (
    <>
      <div className="product-list-table-body-row">
        <SpinLoader absolute={true} small={true} loading={updateLoading || copyLoading || removeLoading} />
        <div className="product-list-table-name">
          <div className="d-flex align-items-center">
            <Link
              className="order-thumb small"
              to={`/product/${rp.product?.variantions[0].id}`}
              style={{backgroundImage: `url('${thumbUrl}')`}}
              // style={{backgroundImage: `url('${process.env.REACT_APP_API_URL}${rp.product.variantions[0]?.photos[0]?.mediaImageGenerateds[0].path}')`}}
            >
              <img src="/assets/ratio2-3.png" alt="" />
            </Link>
            <Link to={`/product/${rp.product?.variantions[0].id}`}>{rp.product.name}</Link>
          </div>
        </div>
        <div className="product-list-table-code">{rp.product.code}</div>
        <div className="product-list-table-material">
          <MaterialChooser product={rp.product} setMaterial={changeMaterial} material={rp.material} id={rp.id} />
        </div>
        <div className="product-list-table-color">{rp.material && <ColorChooser data={rp.material.tagChildrens} setColor={changeColor} color={rp.color} id={rp.id} />}</div>
        <div className="product-list-table-quantity">
          <div className="product-list-quantity">
            {sizes.length > 0 &&
              sizes.map((s, k) => {


                let df,
                  dfarr = rp.sizesqty?.filter((o) => o.name === s.name)
                if (dfarr?.length > 0) df = dfarr[0]

                return (
                  <Fragment key={k}>
                    <SizeChooser s={s} data={df} changeSizes={changeSizes} />
                  </Fragment>
                )
              })}
          </div>
        </div>
        <div className="product-list-table-comment">
          {!rp.comment ? (
            <u className="cursor-pointer" onClick={() => showModal(`writeCommentModal${rp.id}`)}>
              Comment
            </u>
          ) : (
            <>
              <div className="two-line-text">{rp.comment}</div>{" "}
              <u className="cursor-pointer" onClick={() => showModal(`writeCommentModal${rp.id}`)}>
                Comment
              </u>
            </>
          )}
        </div>
        {!hideAction && (
          <div className="product-list-table-action">
            <div className="d-flex align-items-center justify-content-between">
              <div className="mr-small">
                <u className="cursor-pointer" onClick={submitCopy}>
                  Duplicate
                </u>
              </div>
              <div className="product-list-table-closer" onClick={RemoveProduct}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20.448" height="20.448" viewBox="0 0 20.448 20.448">
                  <g id="Group_1" data-name="Group 1" transform="translate(-1205.646 -358.276)">
                    <path id="Path_3" data-name="Path 3" d="M0,0H27.917" transform="translate(1206 358.63) rotate(45)" fill="none" stroke="#000" strokeWidth="1" />
                    <path id="Path_4" data-name="Path 4" d="M0,0H27.917" transform="translate(1206 378.37) rotate(-45)" fill="none" stroke="#000" strokeWidth="1" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal id={`writeCommentModal${rp.id}`} darkBackdrop>
        <Modal.Body>
          <div className="fs-20 mb-small">Comment</div>

          <form onSubmit={handleSubmit(submitComment)}>
            <div className="form-group">
              <div className="  ">
                <textarea
                  type={"text"}
                  {...register("comment")}
                  //  defaultValue={comment}
                  className="form-control"
                />
              </div>
            </div>

            <button type="submit" className="btn btn-dark">
              Save
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
