import React from "react"
import {createBrowserRouter} from "react-router-dom"
import WideLayout from "../layouts/WideLayout"
import FolderDetail from "../pages/FolderDetail"
import NewsDetail from "../pages/NewsDetail"
import Login from "../pages/Login"
import Orders from "../pages/Orders"
import OrderDetail from "../pages/OrderDetail"
import PriceRequest from "../pages/PriceRequest"
import PriceRequestDetail from "../pages/PriceRequestDetail"
import Product from "../pages/Product"
import ProtectedRoute from "./protected"
import ProfileSettings from "../pages/ProfileSettings"
// import CoreLayout from "../layouts/CoreLayout"
// import ProductsLayout from "../layouts/ProductsLayout"
// import Category from "../pages/Category"
// import Folder from "../pages/Folder"
// import Home from "../pages/Home"
const CoreLayout = React.lazy(() => import(/* webpackChunkName: "layout.core" */ "../layouts/CoreLayout"))
const ProductsLayout = React.lazy(() => import(/* webpackChunkName: "layout.product.list" */ "../layouts/ProductsLayout"))
const FilesLayout = React.lazy(() => import(/* webpackChunkName: "layout.files.list" */ "../layouts/FilesLayout"))

const Home = React.lazy(() => import(/* webpackChunkName: "home" */ "../pages/Home"))
const Category = React.lazy(() => import(/* webpackChunkName: "category" */ "../pages/Category"))
const Folder = React.lazy(() => import(/* webpackChunkName: "folder" */ "../pages/Folder"))
const News = React.lazy(() => import(/* webpackChunkName: "news" */ "../pages/News"))
const Notification = React.lazy(() => import(/* webpackChunkName: "notification" */ "../pages/Notification"))
const Files = React.lazy(() => import(/* webpackChunkName: "files" */ "../pages/Files"))

export default createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <CoreLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: (
          <React.Suspense fallback={<>...</>}>
            <ProductsLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Home />
              </React.Suspense>
            ),
          },
          {
            path: "/category",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Category />
              </React.Suspense>
            ),
          },
          {
            path: "/category/:categoryId",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Category />
              </React.Suspense>
            ),
          },
          {
            path: "/category/:categoryId/:id",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Product />
              </React.Suspense>
            ),
          },
          {
            path: "/search/:search",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Category />
              </React.Suspense>
            ),
          },
          {
            path: "/product/:id",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Product />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "/folders",
        element: (
          <React.Suspense fallback={<>...</>}>
            <WideLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/folders",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Folder />
              </React.Suspense>
            ),
          },
          {
            path: "/folders/:id",
            element: (
              <React.Suspense fallback={<>...</>}>
                <FolderDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "/notification",
        element: (
          <React.Suspense fallback={<>...</>}>
            <WideLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/notification",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Notification />
              </React.Suspense>
            ),
          },
        ]
      },
      {
        path: "/news",
        element: (
          <React.Suspense fallback={<>...</>}>
            <WideLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/news",
            element: (
              <React.Suspense fallback={<>...</>}>
                <News />
              </React.Suspense>
            ),
          },
          {
            path: "/news/:id",
            element: (
              <React.Suspense fallback={<>...</>}>
                <NewsDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "/files",
        element: (
          <React.Suspense fallback={<>...</>}>
            <FilesLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/files",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Files />
              </React.Suspense>
            ),
          },
          {
            path: "/files/:category",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Files />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "/orders",
        element: (
          <React.Suspense fallback={<>...</>}>
            <WideLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/orders",
            element: (
              <React.Suspense fallback={<>...</>}>
                <Orders />
              </React.Suspense>
            ),
          },
          {
            path: "/orders/:id",
            element: (
              <React.Suspense fallback={<>...</>}>
                <OrderDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "/price-requests",
        element: (
          <React.Suspense fallback={<>...</>}>
            <WideLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/price-requests",
            element: (
              <React.Suspense fallback={<>...</>}>
                <PriceRequest />
              </React.Suspense>
            ),
          },
          {
            path: "/price-requests/:id",
            element: (
              <React.Suspense fallback={<>...</>}>
                <PriceRequestDetail />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "/settings",
        element: (
          <React.Suspense fallback={<>...</>}>
            <WideLayout />
          </React.Suspense>
        ),
        children: [
          {
            path: "/settings",
            element: <ProfileSettings />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <WideLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
])
