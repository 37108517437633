import React, { useCallback, useEffect, useRef, useState } from "react"
import LightGallery from "lightgallery/react"

// import styles
import "lightgallery/css/lightgallery.css"
import "lightgallery/css/lg-zoom.css"
import "lightgallery/css/lg-thumbnail.css"

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss"
import "lightgallery/scss/lg-zoom.scss"

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail"
import lgZoom from "lightgallery/plugins/zoom"

export default function PhotoGallery({ galleryItems, openIndex }) {
  const lightGallery = useRef(null)
  const [items, setItems] = useState(galleryItems)

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance
    }
  }, [])

  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a key={item.id} data-lg-size={item.size} className="gallery-item" data-src={item.src}>
          <img style={{ maxWidth: "280px" }} className="img-responsive" src={item.thumb} />
        </a>
      )
    })
  }, [items])

  // useEffect(() => {
  //   // lightGallery.current.refresh()
  //   // lightGallery.current.openGallery(0)
  // }, [items])

  useEffect(() => {
    if (parseInt(openIndex) >= 0) {
      lightGallery.current.openGallery(openIndex)
    }
  }, [openIndex])

  return (
    <div className="App">
      <LightGallery plugins={[lgZoom]} elementClassNames="" onInit={onInit} dynamic={true} dynamicEl={items}>
        {/* {getItems()} */}
      </LightGallery>
    </div>
  )
}
