import {useMutation} from "@apollo/client"
import React from "react"
import {UPDATE_ORDER_PRODUCT_ATTRIBUTE} from "../graphql/mutation"
import {useInit} from "../providers/Init"
import {useModal} from "../providers/Modal"
import Dropdown from "./Dropdown"
import Modal from "./Modal"

export default function SizeChooser({s, changeSizes, data}) {
  const inputRef = React.useRef(null)

  // const ct = rp.OrderProductsAttribute.filter((c) => c.attributeId === s.id)[0]
  let count = data?.qty || 0
  // const [count, setCount] = React.useState(data?.qty || 0)
  const [UpdateOrderProductAttribute] = useMutation(UPDATE_ORDER_PRODUCT_ATTRIBUTE)
  // console.log(count)
  const choose = async (newCount) => {
  
    if (newCount >= 3 || newCount == 0) {
      // setCount(newCount)
      inputRef.current.value = newCount
      changeSizes({
        name: s.name,
        qty: newCount,
      })
    }
  }

  React.useEffect(() => {
    return () => {
      // setCount(0)
    }
  }, [])
  return (
    <>
      <div className="product-list-quantity-item">
        <div className="product-list-quantity-item-name ">{s.name}</div>
        <div className="product-list-quantity-item-control">
          <div className="product-list-quantity-item-btn product-list-quantity-item-plus" onClick={() => choose(count < 3 ? 3 : count + 1)}>
            +
          </div>
          <input ref={inputRef} type="number" value={count} className="product-list-quantity-item-input number" />
          <div className="product-list-quantity-item-btn product-list-quantity-item-menus" onClick={() => choose(count <= 3 ? 0 : count - 1)}>
            -
          </div>
        </div>
      </div>
    </>
  )
}
