import React from "react"

export default function StickyWrapper({children, off = false}) {
  const [top, setTop] = React.useState(100)
  const [bottom, setBottom] = React.useState(null)
  const [width, setWidth] = React.useState(0)
  const wrapper = React.useRef(null)
  const div = React.useRef(null)

  const handleScroll = (event) => {
    if (!off) {
      let wrapperRectTop = wrapper.current.getBoundingClientRect().top
      if (window.innerHeight - 100 > div.current?.clientHeight) {
        if (window.scrollY > 100) {
          setTop(window.scrollY - Math.abs(wrapperRectTop))
        }
        setBottom(0)
      } else {
        let scrollPosBottom = window.scrollY + window.innerHeight - 100
        let divRectBottom = div.current.getBoundingClientRect().bottom

        setBottom(0)
        if (scrollPosBottom < divRectBottom) {
          setTop(wrapperRectTop)
        } else {
          setBottom(80)
        }
      }
    }
  }
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, {passive: true})
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [bottom])

  React.useEffect(() => {
    // console.log(wrapper.current?.offsetWidth);
    setWidth(wrapper.current?.offsetWidth)
  }, [width])

  // console.log(off);
  return (
    <div ref={wrapper} className="h-100">
      <div ref={div} style={bottom ? {bottom: `${bottom}px`, width: `${width}px`} : {top: `${top}px`, width: `${width}px`}} className={`d-inline-block ${off ? "" : "pos-sticky"}`}>
        {width > 0 && children}
      </div>
    </div>
  )
}

// $(window).scroll(function(event) {
//   var window_width = $(window).width();
//   var gallery_height_orig = $(".gallery").height();
//   var product_height = $(".product-detial").height();
//   if (window_width > 765 && gallery_height_orig > product_height) {
//     var scTop = $(window).scrollTop();
//     var smallestWindow = false;
//     if (scTop >= 130) {
//       var product_width = $(".product-detial").width() + 40;
//       var window_height = $(window).height();
//       var gallery_height = $(".gallery").height() + 130;
//       var fixed_top_pos = 0;
//       var fixed_check = 0;
//       if (window_height - 96 > product_height) {
//         smallestWindow = false;
//         fixed_top_pos = 0;
//       } else if (window_height <= product_height) {
//         smallestWindow = true;
//         fixed_check = product_height - window_height + 130;
//         fixed_top_pos = product_height - window_height;
//         fixed_top_pos = -fixed_top_pos;
//       }
//       if (smallestWindow == true) {
//         var w = gallery_height - window_height;
//         if (scTop >= fixed_check && scTop < w) {
//           $(".product-detial").css("width", product_width + "px");
//           $(".product-detial").css("top", fixed_top_pos + "px");
//           $(".product-detial").addClass("product-fixed");

//         } else if (scTop >= fixed_check && scTop > w) {
//           var fixed_top_pos = w - scTop + fixed_top_pos;
//           $(".product-detial").css("width", product_width + "px");
//           $(".product-detial").css("top", fixed_top_pos + "px");
//           $(".product-detial").addClass("product-fixed");
//         }
//       } else if (smallestWindow == false) {
//         var w = gallery_height - window_height;
//         if (scTop >= fixed_check && scTop < w) {
//           $(".product-detial").css("width", product_width + "px");
//           $(".product-detial").css("top", fixed_top_pos + "px");
//           $(".product-detial").addClass("product-fixed");

//         } else if (scTop >= fixed_check && scTop > w) {
//           var fixed_top_pos = w - scTop + fixed_top_pos;
//           $(".product-detial").css("width", product_width + "px");
//           $(".product-detial").css("top", fixed_top_pos + "px");
//           $(".product-detial").addClass("product-fixed");
//         }
//       }
//     } else {
//       if ($(".product-detial").hasClass("product-fixed")) {
//         $(".product-detial").css("width", "auto");
//         $(".product-detial").css("top", "0px");
//         $(".product-detial").removeClass("product-fixed");
//       }
//     }
//   }
// });
