import {useMutation} from "@apollo/client"
import React from "react"
import {toast} from "react-hot-toast"
import SpinLoader from "../../../components/loaders/SpinLoader"
import {DELETE_ORDER, SEND_ORDER} from "../../../graphql/mutation"
import Modal from "../../../components/Modal"
import {useModal} from "../../../providers/Modal"
import {useNavigate} from "react-router-dom"

export default function DeleteOrderButton({id}) {
  const {modalId, showModal} = useModal()
  const navigate = useNavigate()
  const [DeleteOrder, {loading, data}] = useMutation(DELETE_ORDER, {
    variables: {
      id: id,
    },
    onCompleted: () => {
      toast.success("Deleted")
      navigate("/orders")
    },
    refetchQueries: ["GetOrderList"],
  })

  return (
    <>
      <button className="btn btn-light" disabled={loading} onClick={DeleteOrder}>
        <div className="d-flex align-items-center justify-content-center">
          <SpinLoader loading={loading} small /> Delete
        </div>
      </button>
      <Modal medium id={`orderModal${id}`}>
        <Modal.Body>
          <div className="mb-small d-flex align-items-start justify-content-between fs-14">
            <div className="mr-mini" style={{maxWidth: "20px", maxHeight: "20px", paddingTop: "3px"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_59563" data-name="Rectangle 59563" width="18" height="18" fill="none" />
                  </clipPath>
                </defs>
                <g id="Group_61539" data-name="Group 61539" clipPath="url(#clip-path)">
                  <path id="Path_136460" data-name="Path 136460" d="M18,9A9,9,0,1,1,9,0a9,9,0,0,1,9,9" transform="translate(0 0)" fill="#00a64c" />
                  <path
                    id="Path_136461"
                    data-name="Path 136461"
                    d="M15.139,22.412l-4.5-4.119a.675.675,0,1,1,.911-1l3.429,3.138,5.2-6.6a.675.675,0,1,1,1.06.836Z"
                    transform="translate(-6.901 -8.994)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div>Your request has been sent. Please note that any further communication or details will be via email (trade@munkhcashmere.mn).</div>
          </div>
          <div className="w-100 text-right">
            <button className="btn btn-light" onClick={() => showModal("")}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
