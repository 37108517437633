import React, {useState} from "react"
import {useMutation, useQuery} from "@apollo/client"
import {Link, useParams} from "react-router-dom"
import {GET_REQUEST_DETAIL} from "../../graphql/query"
import ProductRequestRow from "./components/ProductRequestRow"
import SpinLoader from "../../components/loaders/SpinLoader"
import SendRequestButton from "./components/SendRequestButton"
import moment from "moment"
import {useInit} from "../../providers/Init"
import DownloadRequestExcel from "./components/DownloadRequestExcel"
import DeleteRequestButton from "./components/DeleteRequestButton"
moment().format()

export default function RequestDetailScreen() {
  let {id} = useParams()

  const [products, setProducts] = useState()
  const {data, loading} = useQuery(GET_REQUEST_DETAIL, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.GetRequestDetail?.products) {
        setProducts(data.GetRequestDetail.products)
      }
    },
  })

  React.useEffect(() => {}, [products])

  if (loading)
    return (
      <div className="pos-rel h-100 list-loader-wrap">
        <SpinLoader loading={loading} />
      </div>
    )

  if (!data) return "not found"

  const request = data?.GetRequestDetail
  // console.log("request", request)

  let date = moment(parseInt(request.createdAt)).format("LLLL")
  let updatedAt = request.updatedAt ? moment(parseInt(request.updatedAt)).format("LLLL") : ""

  return (
    <>
      <div className="mb-medium d-flex align-items-center justify-content-between">
        <div>
          <h1 className="mb-0">
            {request.status === "active" && "New price quotation request detail"}
            {request.status === "pending" && "Pending price quotation request detail"}
            {request.status === "completed" && "Previous price quotation request detail"}
          </h1>
          {updatedAt && (
            <span className="text-color-secondary mr-small fs-12">
              <i>Last updated at {updatedAt}</i>
            </span>
          )}
        </div>
        <div className="">
          {request.status === "completed" && <DeleteRequestButton id={id} />}
          {request.status !== "completed" && <SendRequestButton id={id} />}
        </div>
      </div>
      <div className="bg-white">
        <div className="order-info mb-medium d-flex align-items-center justify-content-between">
          <div className="">{date}</div>

          <div className="d-flex align-items-center">
            <div className="text-color-secondary mr-small">{request.products?.length || 0} products</div>
            <DownloadRequestExcel id={id} />
          </div>
        </div>

        {products?.length > 0 ? (
          <div className="product-list-table prev-order-wrap">
            <div className="product-list-table-head">
              <div className="product-list-table-name">Product</div>
              <div className="product-list-table-code">Code</div>
              <div className="product-list-table-material">Material</div>
              <div className="product-list-table-comment">Comment</div>
              {request.status !== "completed" && <div className="product-list-table-action">Action</div>}
            </div>
            <div className="product-list-table-body">
              {products.map((rp, key) => {
                return <ProductRequestRow key={key} rp={rp} hideAction={request.status !== "completed" ? false : true} />
              })}
            </div>
          </div>
        ) : (
          <div className="text-center">No products</div>
        )}
      </div>
    </>
  )
}
